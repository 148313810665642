import { Button, Tooltip } from '@fluentui/react-components';
import {useState} from 'react'
import { Modal, Spinner } from 'react-bootstrap';
import { MapContainer, TileLayer, Marker, Popup, useMap} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import { GetDeviceLocation } from '../../features/deviceLocationSlice';
import Moment from 'react-moment';
import { useEffect } from 'react';

const DeviceLocationModal = ({device}) => {
    const dispatch = useDispatch();
    const {deviceLocation, isLoading} = useSelector(state => state.location);
    const [modalCentered, setModalCentered] = useState(false);
    const [selectionRange, setSelectionRange] = useState({
      startDate: new Date(),
      endDate: new Date(),
    });

    const ComponentResize = () => {
      const map = useMap();

      setTimeout(() => {
        map.invalidateSize();
      }, 0);

      return null;
    };

    function handleCallback(start, end, label) {
      setSelectionRange({
        startDate: new Date(start._d),
        endDate: new Date(end._d),
      });

      const data = {
        id: device.device_id,
        start_date: new Date(start._d).toISOString().slice(0, 19),
        end_date: new Date(end._d).toISOString().slice(0, 19),
      };
      dispatch(GetDeviceLocation(data));
      
    }

    const onShow = () => {
        const data = {
          id: device.device_id,
          start_date: new Date().toISOString().slice(0, 19),
          end_date: new Date().toISOString().slice(0, 19),
        };
        dispatch(GetDeviceLocation(data));
    }

     const MapRecenter = ({ zoomLevel }) => {
       const map = useMap();

       useEffect(() => {
         // Fly to that coordinates and set new zoom level
         if(deviceLocation.length > 0){
            map.flyTo(
              [deviceLocation[0].lat, deviceLocation[0].lon],
              zoomLevel
            );
         }
         
       }, [deviceLocation]);
       return null;
     };
     
  return (
    <>
      <Tooltip withArrow content={"Location History"}>
        <Button
          onClick={() => setModalCentered(true)}
          appearance="transparent"
          icon={<i className="las la-search-location"></i>}
        ></Button>
      </Tooltip>
      <Modal
        onShow={onShow}
        className="fade"
        show={modalCentered}
        size="xl"
        scrollable
      >
        <Modal.Header>
          <Modal.Title className="d-flex align-items-center">
            {isLoading && (
              <>
                <Spinner animation="border" variant="primary" /> &nbsp;
              </>
            )}{" "}
            {`${device.imei} - Location`}
          </Modal.Title>
          <Button
            onClick={() => setModalCentered(false)}
            variant=""
            className="close"
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <DateRangePicker
              initialSettings={selectionRange}
              onCallback={handleCallback}
            >
              <input type="text" className="form-control" />
            </DateRangePicker>
          </div>
          <MapContainer
            center={[33.8, 73.301]}
            zoom={9}
            style={{ height: "500px" }}
          >
            <ComponentResize />
            <MapRecenter zoomLevel={9} />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {deviceLocation.map((loc) => (
              <Marker position={[loc.lat, loc.lon]}>
                <Popup>
                  <Moment format="LLL">{loc.created_at}</Moment>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeviceLocationModal;