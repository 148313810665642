import React, { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  Input,
  Button,
  Field
} from "@fluentui/react-components";
import { useDispatch } from "react-redux";
import { CreateGroup, UpdateGroup } from "../../features/groupSlice";
import { toast } from "react-toastify";

function AddOrUpdateGroup({ data }) {
    const dispatch = useDispatch();

    const [group, setGroup] = useState({
        "id": data?.id ?? "",
        "name": data?.name ?? ""
    });

    const onChangeInput = (e) => {
      const { name, value } = e.target;

      setGroup({ ...group, [name]: value });
    };

    const onSubmit = (e) => {

        e.preventDefault();
        const tst = toast.loading("Loading...");

        if(data){
          dispatch(UpdateGroup(group)).then(
            (response) => {
              if (response.meta.requestStatus === "fulfilled") {
                toast.update(tst, {
                  render: "Group updated successfully!",
                  type: "success",
                  isLoading: false,
                  autoClose: 3000,
                  closeButton: true,
                });
              } else {
                toast.dismiss(tst);
              }
            }
          );
        }else{
          dispatch(CreateGroup({name: group.name})).then((response) => {
            if (response.meta.requestStatus === "fulfilled") {
              toast.update(tst, {
                render: "Group created successfully!",
                type: "success",
                isLoading: false,
                autoClose: 3000,
                closeButton: true,
              });
            } else {
              toast.dismiss(tst);
            }
          });
        }
        

        
    }

  return (
    <Dialog modalType="non-modal">
      <DialogTrigger disableButtonEnhancement>
        {data ? (
          <Button icon={<i className="las la-pen"></i>}>Edit</Button>
        ) : (
          <Button
            appearance="primary"
            icon={<i className="las la-users"></i>}
          >
            Add
          </Button>
        )}
      </DialogTrigger>
      <DialogSurface aria-describedby={undefined}>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <DialogTitle>{data ? "Update Group" : "Add Group"}</DialogTitle>
            <DialogContent>
              <Field
                label="Name"
                required
                size="large"
                className="form-group"
              >
                <Input value={group.name} name="name" onChange={onChangeInput} />
              </Field>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Close</Button>
              </DialogTrigger>
              <Button type="submit" appearance="primary">
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
}

export default AddOrUpdateGroup;
