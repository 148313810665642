import axios from "axios";




function getCookie(key) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

function deleteCookie(cookieName) {

   var d = new Date();
   d.setDate(d.getDate() - 5);

  document.cookie = `${cookieName}=; expires=${d}; path=/`;
}

const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (const cookie of cookies) {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
};

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const token = getCookie("_auth");


const authRequest = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    "access-control-allow-origin": "*",
    "Access-Control-Allow-Headers": "Content-Type, Authorization",
    "Access-Control-Allow-Methods": "*",
  },
});




authRequest.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {

      deleteAllCookies();
      window.location.href = "/page-login";
      
    }
    return Promise.reject(error);
  }
);


const login = async (loginData) => {

    const response = await axios({
      method: "post",
      url: baseUrl + "users/login",
      data: loginData,
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Methods": "*",
      },
    });


    return response.data;
    
};

//User
const getUserList = async (data) => {

    const response = await authRequest({
      method: "post",
      url: "users/list",
      data: data,
    });

    return response.data;
}

const addUser = async (data) => {

  const response = await authRequest({
    method: "post",
    url: "users/create",
    data: data
  });

  return response.data;
};

const updateUser = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "users/update",
    data: data,
  });

  if(response.status === 200){
    return data;
  }

  return response.data;
};



//Group
const getGroupList = async (pagination) => {

  const response = await authRequest({
    method: "post",
    url: "groups/list",
    data: pagination,
  });

  return response.data;
}

const addGroup = async (data) => {

  const response = await authRequest({
    method: "post",
    url: "groups/create",
    data: data
  });

  if(response.status === 200){
    const group = {
      ...data,
      created_at: new Date(),
      modified_at: new Date(),
    };

    group.id = response.data.id;

    return group;
  }

  return response.data;
}

const updateGroup = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "groups/update",
    data: data,
  });

  if(response.status === 200){
    return data;
  }

  return response.data;
};

//Configuration
const getConfigurationList = async (pagination) => {
  const response = await authRequest({
    method: "post",
    url: "configurations/list",
    data: pagination,
  });

  return response.data;
};

const addConfiguration = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "configurations/create",
    data: data,
  });

  if(response.status === 200){
    const conf = {
      created_at: new Date(),
      modified_at: new Date(),
      ...data
    };
    conf.id = response.data.id;
    delete conf.password;

    return conf;
  }

  return response.data;
};

const updateConfiguration = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "configurations/update",
    data: data,
  });

  if (response.status === 200) {
    return data;
  }

  return response.data;
};

const updatePassword = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "configurations/password",
    data: data,
  });

  return response.data;
};

const updateQrcode = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "configurations/qrcode",
    data: data,
  });

  if(response.status === 200){
    return data;
  }

  return response.data;
};

const assignApp = async (data) => {

  const response = await authRequest({
    method: "post",
    url: "configurations/assign_app",
    data: data
  });

  return response.data;
}

const getConfigApps = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "configurations/list_apps",
    data: data
  });

  return response.data;
}

const configupdateApp = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "configurations/update_app",
    data: data,
  });

  return response.data;
};

//Device Management
const getDeviceList = async (pagination) => {
  const response = await authRequest({
    method: "post",
    url: "devices/list",
    data: pagination
  });

  return response.data;
};

const createDevice = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "devices/create",
    data: data
  });

  if(response.status === 200){
    const device = {
      ...data,
      created_at: new Date(),
      modified_at: new Date(),
    };

    device.id = response.data.id;

    return device;
  }

  return response.data;
}

const updateDevice = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "devices/update",
    data: data
  });

  if(response.status === 200){
    return data;
  }

  return response.data;
}

const lockUnlockDevice = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "devices/lock",
    data: data,
  });

  if (response.status === 200) {
    return data;
  }

  return response.data;
};

const resetDevice = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "devices/reset",
    data: data,
  });

  if (response.status === 200) {
    return data;
  }

  return response.data;
};



//application
const uploadAPK = async (data) => {

  const response = await authRequest({
    method: "post",
    url: "applications/upload",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });

  return response.data;

}

const getAppList = async (pagination) => {

  const response = await authRequest({
    method: "post",
    url: "applications/list_apps",
    data: pagination
  });

  return response.data;
}

const getAppVersions = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "applications/list_versions",
    data: data
  });

  return response.data;
}

const addSystemApp = async (data) => {

  const response = await authRequest({
    method: "post",
    url: "applications/add",
    data: data
  });

  return response.data;
}
//POlicy Management
const getPolicyList = async (pagination) => {
  const response = await authRequest({
    method: "post",
    url: "policies/list",
    data: pagination,
  });

  return response.data;
};

const addPolicy = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "policies/create",
    data: data,
  });

  if (response.status === 200) {
    const policy = {
      ...data,
      created_at: new Date(),
      modified_at: new Date(),
    };

    policy.id = response.data.id;

    return policy;
  }

  return response.data;
};

const updatePolicy = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "policies/update",
    data: data,
  });

  if (response.status === 200) {
    return data;
  }

  return response.data;
};

const getConfigOrDevicePolicy = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "policies/get_policy",
    data: data
  });

  return response.data;
}

const assignPolicy = async (data) => {
   const response = await authRequest({
     method: "post",
     url: "policies/assign",
     data: data,
   });

   return response.data;
}

//Device Location
const getDevicesLocation = async (pagination) => {
  const response = await authRequest({
    method: "post",
    url: "devices/devices_loc",
    data: pagination,
  });

  return response.data;
};

const getDeviceLocation = async (data) => {
  const response = await authRequest({
    method: "post",
    url: "devices/device_loc",
    data: data,
  });

  return response.data;
};



export {
    login,
    getUserList,
    getGroupList,
    addGroup,
    updateGroup,
    getConfigurationList,
    addConfiguration,
    updateConfiguration,
    updatePassword,
    updateQrcode,
    getDeviceList,
    createDevice,
    updateDevice,
    uploadAPK,
    getAppList,
    assignApp,
    getAppVersions,
    getConfigApps,
    getPolicyList,
    addPolicy,
    updatePolicy,
    configupdateApp,
    getConfigOrDevicePolicy,
    assignPolicy,
    getDevicesLocation,
    getDeviceLocation,
    lockUnlockDevice,
    resetDevice,
    addUser,
    updateUser,
    addSystemApp
};
