import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import "leaflet/dist/leaflet.css";

import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import icon from "leaflet/dist/images/marker-icon.png";
import L from "leaflet";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { useDispatch, useSelector } from 'react-redux';
import {Spinner, Tooltip } from '@fluentui/react-components';
import { GetDevicesLocation, reset } from '../../features/deviceLocationSlice';
import { Card as FCard, CardHeader, Text, Caption1, Button, Input } from '@fluentui/react-components';
import Moment from 'react-moment';
import DeviceLocationModal from './deviceLocationModal';



const DevicesLocation = () => {
    const { isLoading, devices } = useSelector((state) => state.location);
    const [index, setIndex] = useState(0);
    const dispatch = useDispatch();
    const [position, setPosition] = useState([33.8, 73.301]);
    const [search, setSearch] = useState("");
    
    let DefaultIcon = L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow,
    });

    L.Marker.prototype.options.icon = DefaultIcon;

    useEffect(() => {
        dispatch(GetDevicesLocation({
            "limit": 100,
            "offset": index * 100
        }));

        return () => {
            dispatch(reset());
        }
    },[dispatch, index]);

   const MapRecenter = ({ position, zoomLevel }) => {
     const map = useMap();

     useEffect(() => {
       // Fly to that coordinates and set new zoom level
       map.flyTo(position, zoomLevel);
     }, [position]);
     return null;
   };


  return (
    <Card>
      <Card.Header>
        <Card.Title className="d-flex align-items-center">
          {isLoading && (
            <>
              <Spinner appearance="primary" /> &nbsp;
            </>
          )}{" "}
          Devices Location
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg={9}>
            <MapContainer
              center={position}
              zoom={5}
              style={{ width: "100%", height: "80vh" }}
            >
              <MapRecenter position={position} zoomLevel={5} />
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {devices.map((device) => (
                <Marker position={[device.lat, device.lon]}>
                  <Popup>
                    {
                      <>
                        <strong>{device.imei}</strong>
                        <br />({device.lat}, {device.lon})
                        <br />
                        <Moment format="LLL">{device.created_at}</Moment>
                      </>
                    }
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
          </Col>
          <Col style={{ height: "80vh", overflow: "scroll" }}>
            <>
              <div className="form-group">
                <Tooltip withArrow content={"Load More Devices"}>
                  <Button
                    className="w-100"
                    appearance="primary"
                    icon={<i className="las la-mobile"></i>}
                    disabled={devices.length < (index + 1) * 100}
                    onClick={() => setIndex(prev => prev + 1)}
                  >
                    Load More
                  </Button>
                </Tooltip>
              </div>
              <div className="form-group w-100">
                <Input
                  placeholder="Enter IMEI number"
                  className="w-100"
                  contentBefore={<i className="las la-1x la-search"></i>}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              {devices
                .filter((device) => {
                  return device["imei"]
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase());
                })
                .map((device) => (
                  <FCard
                    key={device.device_id}
                    size="small"
                    role="listitem"
                    className="mb-3"
                  >
                    <CardHeader
                      image={<i class="las la-2x la-mobile"></i>}
                      header={<Text weight="semibold">{device.imei}</Text>}
                      description={
                        <>
                          <Caption1>
                            {`(${device.lat}, ${device.lon})`} <br />{" "}
                            <Moment format="LLL">{device.created_at}</Moment>
                          </Caption1>
                        </>
                      }
                      action={
                        <>
                          <Tooltip withArrow content={"View on map."}>
                            <Button
                              appearance="transparent"
                              icon={<i class="las la-map-marked-alt"></i>}
                              onClick={() =>
                                setPosition([device.lat, device.lon])
                              }
                            />
                          </Tooltip>
                          <DeviceLocationModal device={device} />
                        </>
                      }
                    />
                  </FCard>
                ))}
            </>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default DevicesLocation;