import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { addUser, getUserList, updateUser} from '../api';
import { toast } from 'react-toastify';

const initialState = {
    users: [],
    roles: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

export const GetUserList = createAsyncThunk('user/getList', async (data, thunkAPI) => {
    try{
        
        return await getUserList(data);
    }catch (error){
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        return thunkAPI.rejectWithValue(message);
    }
});

export const CreateUser = createAsyncThunk(
  "user/create",
  async (data, thunkAPI) => {
    try {
      return await addUser(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const UpdateUser = createAsyncThunk(
  "user/update",
  async (data, thunkAPI) => {
    try {
      return await updateUser(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);





export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers : (builder) => {
        builder
          .addCase(GetUserList.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(GetUserList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.users = action.payload.users;
          })
          .addCase(GetUserList.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = false;
            state.message = action.payload;
            toast.error(action.payload);
          })

          .addCase(CreateUser.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(CreateUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
          })
          .addCase(CreateUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = false;
            state.message = action.payload;
            toast.error(action.payload);
          })

          .addCase(UpdateUser.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(UpdateUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.users = state.users.map(u => (
              u.id === action.payload.id ? action.payload : u
            ));
          })
          .addCase(UpdateUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = false;
            state.message = action.payload;
            toast.error(action.payload);
          });
        
    }
});

export const {reset} = userSlice.actions;
export default userSlice.reducer;