
import {
  Persona,
  TableBody,
  TableCell,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "@fluentui/react-components";
import Moment from "react-moment";
import { Table } from "react-bootstrap";

function AppTable({ data }) {
  return (
      <Table responsive="sm" striped>
        <TableHeader>
          <TableRow>
            <TableHeaderCell scope="col">Name</TableHeaderCell>
            <TableHeaderCell scope="col">Version</TableHeaderCell>
            <TableHeaderCell scope="col">Created At</TableHeaderCell>
            <TableHeaderCell scope="col">Last Update</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <TableCellLayout
                  truncate
                  media={
                    <Persona
                      avatar={{ icon: <i className="lab la-android"></i> }}
                      name={item.name}
                      secondaryText={item.package_name}
                    />
                  }
                ></TableCellLayout>
              </TableCell>
              <TableCell>
                <TableCellLayout
                  media={<i className="las la-1x la-code-branch"></i>}
                >
                  {item.version}
                </TableCellLayout>
              </TableCell>
              <TableCell>
                <TableCellLayout
                  media={<i className="las la-1x la-plus-square"></i>}
                >
                  <Moment format="LLL">{item.created_at}</Moment>
                </TableCellLayout>
              </TableCell>
              <TableCell>
                <TableCellLayout media={<i className="las la-1x la-edit"></i>}>
                  <Moment format="LLL">{item.modified_at}</Moment>
                </TableCellLayout>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
  );
}

export default AppTable;
