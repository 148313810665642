import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { Card, CardHeader, Text, Field, ProgressBar } from '@fluentui/react-components';

const UserCounts = ({data}) => {
    const total = data.length;
    const active = data.filter((item) => item.active).length;
    const inactive = data.filter((item) => !item.active).length;
  return (
    <Row>
      <Col>
        <Card size="small" role="listitem">
          <CardHeader
            image={<i className="las la-2x la-user"></i>}
            header={<Text weight="semibold">Total Users</Text>}
            description={
              <Field
                className="d-block w-100 py-2"
                validationMessage={`The total number of users is ${total}.`}
                validationState="none"
              >
                <ProgressBar max={total} value={total} />
              </Field>
            }
          />
        </Card>
      </Col>
      <Col>
        <Card size="small" role="listitem">
          <CardHeader
            image={<i className="las la-2x la-user-check"></i>}
            header={<Text weight="semibold">Active Users</Text>}
            description={
              <Field
                className="d-block w-100 py-2"
                validationMessage={`${active} - ${
                  (active / total) * 100
                }% of total ${total}.`}
                validationState="none"
              >
                <ProgressBar max={total} value={active} />
              </Field>
            }
          />
        </Card>
      </Col>
      <Col>
        <Card size="small" role="listitem">
          <CardHeader
            image={<i className="las la-2x la-user-times"></i>}
            header={<Text weight="semibold">Inactive Users</Text>}
            description={
              <Field
                className="d-block w-100 py-2"
                validationMessage={`${inactive} - ${
                  (inactive / total) * 100
                }% of total ${total}.`}
                validationState="none"
              >
                <ProgressBar max={total} value={inactive} />
              </Field>
            }
          />
        </Card>
      </Col>
    </Row>
  );
}

export default UserCounts;