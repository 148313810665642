import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getDeviceLocation,
  getDevicesLocation,
} from "../api";
import { toast } from "react-toastify";

const initialState = {
  devices: [],
  deviceLocation: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const GetDevicesLocation = createAsyncThunk(
  "devices/location",
  async (pagination, thunkAPI) => {
    try {
      return await getDevicesLocation(pagination);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetDeviceLocation = createAsyncThunk(
  "device/location",
  async (data, thunkAPI) => {
    try {
      return await getDeviceLocation(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);



export const LocationSlice = createSlice({
  name: "LocationSlice",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetDevicesLocation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetDevicesLocation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        const filterList = action.payload.filter(
          (d) => !state.devices.some((other) => other.device_id === d.device_id)
        );
        state.devices = [...state.devices, ...filterList];
      })
      .addCase(GetDevicesLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(GetDeviceLocation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetDeviceLocation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.deviceLocation = action.payload;
      })
      .addCase(GetDeviceLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      });

      
  },
});

export const { reset } = LocationSlice.actions;
export default LocationSlice.reducer;
