import React from 'react';
import { Spinner } from '@fluentui/react-components';

function CustomSpinner({label}) {
  return (
     <div className="loadingSpinnerContainer">
        <Spinner appearance='primary' labelPosition='before' label={label ?? ""} />
    </div>
  )
}

export default CustomSpinner;