import { useEffect, React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UploadAppAPK from "./modal";
import { Card } from "react-bootstrap";
import { CardHeader, Spinner, Switch } from "@fluentui/react-components";
import CustomMessageBar from "../CustomMessageBar";
import UsePagination from "../../hooks/pagination";
import UseSearch from "../../hooks/search";
import { GetAppList, reset } from "../../features/applicationSlice";
import AppTable from "./table";
import AddSystemAppModal from "./addSystemApp";

function AppManagement() {
  const [searchField, setSearchField] = useState("name");
  const [showSystemApp, setShowSystemApp] = useState(false);
  const [search, setSearch] = useState("");
  const options = [{ label: "Name", value: "name" }];
  const { apps, isLoading, isError, message } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const [perPage, setPerPage] = useState(150);
  const pagination = UsePagination(
    apps,
    index,
    perPage,
    setPerPage,
    setIndex
  );
  const SearchForm = UseSearch(options, setSearchField, setSearch);

  useEffect(() => {
    dispatch(
      GetAppList({
        limit: perPage,
        offset: index * perPage,
      })
    );

    return () => {
      dispatch(reset());
    };
  }, [index, perPage, dispatch]);

  const onChange = (e, d) => {
    if (e.currentTarget.checked) {
      setShowSystemApp(true);
    } else {
     setShowSystemApp(false);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="d-flex align-items-center">
          {isLoading && (
            <>
              <Spinner appearance="primary" /> &nbsp;
            </>
          )}{" "}
          Application List
        </Card.Title>
        <div>
          <UploadAppAPK /> &nbsp;
          <AddSystemAppModal />
        </div>
      </Card.Header>
      <Card.Body>
        <Card className="p-3">
          <CardHeader
            header={
              <Switch
                onChange={(e) => onChange(e)}
                value={showSystemApp}
                label={"Show System App"}
                checked={showSystemApp}
                shape="circular"
              />
            }
          />
        </Card>
        {SearchForm}
        {isError && apps.length === 0 ? (
          !isLoading && (
            <CustomMessageBar
              intent={"error"}
              title={"Error"}
              message={message}
              action={() =>
                dispatch(
                  GetAppList({
                    limit: perPage,
                    offset: index * perPage,
                  })
                )
              }
            />
          )
        ) : (
          <AppTable
            data={apps
              .filter((u) => {
                return showSystemApp ? u.version === "0" : u.version !== "0";
              })
              .filter((u) => {
                return u[searchField]
                  .toString()
                  .toLowerCase()
                  .includes(search.toLowerCase());
              })}
          />
        )}
      </Card.Body>
      <Card.Footer>{pagination}</Card.Footer>
    </Card>
  );
}

export default AppManagement;
