import React, { Component } from "react";


/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";
import { useAuthUser } from "react-auth-kit";




class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
   componentWillUnmount() {
      this.mm.dispose();
   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}


const SideBar = () => {
      
      
      /// Path
      const path = window.location.pathname;
      
      /// Active menu
      let configurationManagement = ["manage-configurations"],
        userManagement = ["manage-users"],
        groupManagement = ["manage-groups"],
        appManagement = ["manage-applications"],
        policyManagement = ["manage-policies"],
        deviceManagement = ["manage-devices"],
        reports = ["devices-location"];

      const auth = useAuthUser();
      
         
         
         

      return (
        <div className="deznav">
          <PerfectScrollbar className="deznav-scroll">
            <MM className="metismenu" id="menu">
              <li
                className={`${
                  configurationManagement.includes(path.slice(1))
                    ? "mm-active"
                    : ""
                }`}
              >
                <Link className="has-arrow ai-icon" to="#">
                  <i className="las la-2x la-tools"></i>
                  <span className="nav-text">Configuration Management</span>
                </Link>
                <ul>
                  <li>
                    <Link to="/manage-configurations">Configurations</Link>
                  </li>
                </ul>
              </li>
             {auth().role_id === 1 && <li
                className={`${
                  groupManagement.includes(path.slice(1)) ? "mm-active" : ""
                }`}
              >
                <Link className="has-arrow ai-icon" to="#">
                  <i className="las la-2x la-users"></i>
                  <span className="nav-text">Group Management</span>
                </Link>
                <ul>
                  <li>
                    <Link to="/manage-groups">Groups</Link>
                  </li>
                </ul>
              </li>}
              <li
                className={`${
                  deviceManagement.includes(path.slice(1)) ? "mm-active" : ""
                }`}
              >
                <Link className="has-arrow ai-icon" to="#">
                  <i className="las la-2x la-mobile"></i>
                  <span className="nav-text">Device Management</span>
                </Link>
                <ul>
                  <li>
                    <Link to="/manage-devices">Devices</Link>
                  </li>
                </ul>
              </li>
              {auth().role_id === 1 &&<li
                className={`${
                  appManagement.includes(path.slice(1)) ? "mm-active" : ""
                }`}
              >
                <Link className="has-arrow ai-icon" to="#">
                  <i className="la la-2x la-android"></i>
                  <span className="nav-text">App Management</span>
                </Link>
                <ul>
                  <li>
                    <Link to="/manage-applications">Applications</Link>
                  </li>
                </ul>
              </li>}
              {auth().role_id === 1 &&<li
                className={`${
                  policyManagement.includes(path.slice(1)) ? "mm-active" : ""
                }`}
              >
                <Link className="has-arrow ai-icon" to="#">
                  <i className="la la-2x la-file-alt"></i>
                  <span className="nav-text">Policy Management</span>
                </Link>
                <ul>
                  <li>
                    <Link to="/manage-policies">Policies</Link>
                  </li>
                </ul>
              </li>}
              {auth().role_id === 1 &&<li
                className={`${
                  userManagement.includes(path.slice(1)) ? "mm-active" : ""
                }`}
              >
                <Link className="has-arrow ai-icon" to="#">
                  <i className="las la-2x la-user"></i>
                  <span className="nav-text">User Management</span>
                </Link>
                <ul>
                  <li>
                    <Link to="/manage-users">User</Link>
                  </li>
                </ul>
              </li>}
              <li
                className={`${
                  reports.includes(path.slice(1)) ? "mm-active" : ""
                }`}
              >
                <Link className="has-arrow ai-icon" to="#">
                  <i className="las la-2x la-chart-bar"></i>
                  <span className="nav-text">Reports</span>
                </Link>
                <ul>
                  <li>
                    <Link to="/devices-location">Devices Location</Link>
                  </li>
                </ul>
              </li>
            </MM>
          </PerfectScrollbar>
        </div>
      );
}

export default SideBar;
