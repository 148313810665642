import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { USER_ROLES } from '../../constants/constants';

import { Dialog, DialogActions, Select, DialogBody, DialogTrigger, DialogSurface, DialogTitle, DialogContent, Field, Input, Button, Dropdown, Option } from '@fluentui/react-components';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CreateUser, UpdateUser } from '../../../features/userSlice';


function AddOrUpdateUser({data}) {
  const { groups } = useSelector((state) => state.group);
  const dispatch = useDispatch();
  const [defaultSelectedOptions, setDefaultSelectedOptions] = useState([]);
  const [payload, setPayload] = useState({
    id: data?.id ?? null,
    full_name: data?.full_name ?? null,
    username: data?.username ?? null,
    password: data?.password ?? null,
    phone: data?.phone ?? null,
    email: data?.email ?? null,
    role_id: data?.role_id ?? 2,
    active: data?.active ?? 1,
    group_id: data?.group_id ?? [],
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    if(name === 'role_id' || name === 'active'){
      setPayload({ ...payload, [name]: parseInt(value) });

      return;
    }

    setPayload({ ...payload, [name]: value });
  };

  const onOptionSelect = (e, d) => {
    setPayload({...payload, group_id: d.selectedOptions});
  }

  const onSubmit = (e) => {
      e.preventDefault();

      if(!payload.full_name ||
        !payload.email ||
        !payload.phone ||
        payload.group_id.length === 0 ||
        !payload.username
      ){
        toast.info("All fields are required!");
        return;
      }


      const tst = toast.loading("Loading...");

      if (!data) {
        dispatch(CreateUser(payload)).then(
          (response) => {
            if (response.meta.requestStatus === "fulfilled") {
              toast.update(tst, {
                render: "User created successfully!",
                type: "success",
                isLoading: false,
                autoClose: 3000,
                closeButton: true,
              });
            } else {
              toast.dismiss(tst);
            }
          }
        );
      } else {
        dispatch(UpdateUser(payload)).then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            toast.update(tst, {
              render: "User updated successfully!",
              type: "success",
              isLoading: false,
              autoClose: 3000,
              closeButton: true,
            });
          } else {
            toast.dismiss(tst);
          }
        });
      }

  }

  useEffect(() => {
    if(data && groups.length > 0){
      const names = data.group_id.map((id) => {
        const obj = groups.find((item) => item.id === id);
        return obj ? obj.name : null;
      });

      setDefaultSelectedOptions(names);
    }
  },[groups, data])

  


  return (
    <Dialog
      modalType="non-modal"
      onOpenChange={(e, d) =>
        setPayload({
          id: data?.id ?? null,
          full_name: data?.full_name ?? null,
          username: data?.username ?? null,
          password: data?.password ?? null,
          phone: data?.phone ?? null,
          email: data?.email ?? null,
          role_id: data?.role_id ?? 2,
          active: data?.active ?? 1,
          group_id: data?.group_id ?? [],
        })
      }
    >
      <DialogTrigger disableButtonEnhancement>
        {data ? (
          <Button icon={<i className="las la-pen"></i>}>Edit</Button>
        ) : (
          <Button
            appearance="primary"
            icon={<i className="las la-user-plus"></i>}
          >
            Add
          </Button>
        )}
      </DialogTrigger>
      <DialogSurface aria-describedby={undefined}>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <DialogTitle>{data ? "Update User" : "Add User"}</DialogTitle>
            <DialogContent>
              <Field
                label="Full Name"
                required
                size="large"
                className="form-group"
              >
                <Input
                  value={payload.full_name}
                  name="full_name"
                  onChange={onChangeInput}
                />
              </Field>
              <Row>
                <Col>
                  <Field
                    label="Phone"
                    required
                    size="large"
                    className="form-group"
                  >
                    <Input
                      type="number"
                      value={payload.phone}
                      name="phone"
                      onChange={onChangeInput}
                    />
                  </Field>
                </Col>
                <Col>
                  <Field
                    label="Email"
                    required
                    size="large"
                    className="form-group"
                  >
                    <Input
                      type="email"
                      value={payload.email}
                      name="email"
                      onChange={onChangeInput}
                    />
                  </Field>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Field
                    label="Username"
                    required
                    size="large"
                    className="form-group"
                  >
                    <Input
                      value={payload.username}
                      name="username"
                      onChange={onChangeInput}
                    />
                  </Field>
                </Col>
                {!data && (
                  <Col>
                    <Field
                      label="Password"
                      required
                      size="large"
                      className="form-group"
                    >
                      <Input
                        value={payload.password}
                        name="password"
                        type="password"
                        onChange={onChangeInput}
                      />
                    </Field>
                  </Col>
                )}
              </Row>
              <Field
                label="Active"
                required
                size="large"
                className="form-group"
              >
                <Select
                  value={payload.active}
                  name="active"
                  required
                  onChange={onChangeInput}
                >
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </Select>
              </Field>

              <Field label="Role" required size="large" className="form-group">
                <Select
                  name="role_id"
                  value={payload.role_id}
                  required
                  onChange={onChangeInput}
                >
                  {Object.keys(USER_ROLES).map((key) => (
                    <option key={key} value={key}>
                      {USER_ROLES[key]}
                    </option>
                  ))}
                </Select>
              </Field>

              <Field label="Group" required size="large" className="form-group">
                <Dropdown
                  multiselect={true}
                  placeholder="Select Group"
                  onOptionSelect={onOptionSelect}
                  defaultSelectedOptions={payload.group_id}
                  defaultValue={defaultSelectedOptions.join(", ")}
                >
                  {groups.map((gr) => (
                    <Option key={gr.id} text={gr.name} value={gr.id}>
                      {gr.name}
                    </Option>
                  ))}
                </Dropdown>
              </Field>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Close</Button>
              </DialogTrigger>
              <Button type="submit" appearance="primary">
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
}

export default AddOrUpdateUser;