import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addSystemApp, configupdateApp, getAppList, getAppVersions, uploadAPK } from "../api";
import { toast } from "react-toastify";


const initialState = {
  apps: [],
  versions: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const UploadAPK = createAsyncThunk(
  "app/uploadAPK",
  async (data, thunkAPI) => {
    try {
      return await uploadAPK(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetAppList = createAsyncThunk(
  "app/list",
  async (pagination, thunkAPI) => {
    try {
      return await getAppList(pagination);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetAppVersions = createAsyncThunk(
  "app/versions",
  async (data, thunkAPI) => {
    try {
      return await getAppVersions(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const ConfigUpdateApp = createAsyncThunk(
  "app/updateApp",
  async (data, thunkAPI) => {
    try {
      return await configupdateApp(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const AddSystemApp = createAsyncThunk(
  "app/addSystemApp",
  async (data, thunkAPI) => {
    try {
      return await addSystemApp(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);



export const AppSlice = createSlice({
  name: "AppSlice",
  initialState,
  reducers: {
    reset: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(UploadAPK.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UploadAPK.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(UploadAPK.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(GetAppList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAppList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.apps = action.payload;
      })
      .addCase(GetAppList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(GetAppVersions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAppVersions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.versions = action.payload;
      })
      .addCase(GetAppVersions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(ConfigUpdateApp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ConfigUpdateApp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(ConfigUpdateApp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(AddSystemApp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AddSystemApp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(AddSystemApp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      });

      
  },
});

export const { reset } = AppSlice.actions;
export default AppSlice.reducer;
