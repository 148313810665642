import React, { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  Button,
  Tooltip,
  Title3,
} from "@fluentui/react-components";
import { useDispatch } from "react-redux";
import { UpdateQrcode } from "../../features/configurationSlice";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";

function UpdateQrcodeModal({ id, qr_code }) {
  const dispatch = useDispatch();

  const [conf, setConf] = useState({
    id: id,
    qr_code: qr_code,
  });


  function generateRandomString(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const tst = toast.loading("Updating Configuration QrCode!");
    dispatch(UpdateQrcode(conf)).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          toast.update(tst, {
            render: "Configuration QrCode updated successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
          });
        } else {
          toast.dismiss(tst);
        }
    });
  };

  return (
    <Dialog modalType="non-modal">
      <DialogTrigger disableButtonEnhancement>
        <Tooltip withArrow content={"Update Qrcode"}>
          <Button
            icon={<i className="las la-qrcode"></i>}
            onClick={() =>
              setConf({
                id: id,
                qr_code: qr_code,
              })
            }
          ></Button>
        </Tooltip>
      </DialogTrigger>
      <DialogSurface aria-describedby={undefined}>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <DialogTitle>Update QrCode</DialogTitle>
            <DialogContent>
              <div className="d-flex flex-column align-items-center">
                <Tooltip withArrow content={conf.qr_code}>
                  <QRCode className="py-3" size={450} value={conf.qr_code} />
                </Tooltip>

                <Button
                  onClick={() => {
                    setConf({ ...conf, qr_code: generateRandomString(50) });
                  }}
                  className="mt-3"
                  appearance="primary"
                  size="large"
                  icon={<i className="las la-sync"></i>}
                >
                  Refresh
                </Button>
              </div>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Close</Button>
              </DialogTrigger>
              <Button type="submit" appearance="primary">
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
}

export default UpdateQrcodeModal;
