import AddOrUpdateDevice from "./modal";
import {
  Avatar,
  Badge,
  Persona,
  TableBody,
  TableCell,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Table
} from "@fluentui/react-components";
import Moment from "react-moment";
import LiveIndicator from "../LiveIndicator";
import AssignPolicyModal from "../PolicyManagement/assignPolicy";
import LockOrUnlockDevice from "./lock";
import ResetDevice from "./reset";


function DeviceTable({ data }) {
  return (
    <Table className="table table-striped table-responsive">
      <TableHeader>
        <TableRow>
          <TableHeaderCell scope="col">Device</TableHeaderCell>
          <TableHeaderCell scope="col">imei</TableHeaderCell>
          <TableHeaderCell scope="col">Locked/Unlocked</TableHeaderCell>
          <TableHeaderCell scope="col">Reset (Yes/No)</TableHeaderCell>
          {/* <TableHeaderCell scope="col">Created At</TableHeaderCell>
          <TableHeaderCell scope="col">Last Update</TableHeaderCell> */}
          <TableHeaderCell scope="col">Action</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((item) => (
          <TableRow key={item.id}>
            <TableCell>
              <TableCellLayout
                media={
                  <Persona
                    avatar={{}}
                    presence={{
                      status: item.status ? "available" : "busy",
                      icon: (
                        <LiveIndicator
                          bg={item.status ? "success" : "danger"}
                        />
                      ),
                    }}
                    name={item.description}
                    secondaryText={item.status ? "Online" : "Offline"}
                  />
                }
              ></TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout>{item.imei}</TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout>
                {item.device_lock ? (
                  <Badge color="danger">Locked</Badge>
                ) : (
                  <Badge color="success">Unlocked</Badge>
                )}
              </TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout>
                {item.device_reset ? (
                  <Badge color="danger">Yes</Badge>
                ) : (
                  <Badge color="success">No</Badge>
                )}
              </TableCellLayout>
            </TableCell>
            {/* <TableCell>
              <TableCellLayout
                media={<i className="las la-1x la-plus-square"></i>}
              >
                <Moment format="LLL">{item.created_at}</Moment>
              </TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout media={<i className="las la-1x la-edit"></i>}>
                <Moment format="LLL">{item.modified_at}</Moment>
              </TableCellLayout>
            </TableCell> */}
            <TableCell>
              <TableCellLayout>
                <AddOrUpdateDevice data={item} />
                <AssignPolicyModal id={item.id} />
                <LockOrUnlockDevice device={item} />
                {!item.device_reset && <ResetDevice device={item} />}
              </TableCellLayout>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default DeviceTable;
