import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Tooltip,
  Button,
  Avatar,
  Subtitle1,
  Subtitle2,
  DialogActions,
} from "@fluentui/react-components";
import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FactoryResetDevice } from "../../features/deviceSlice";

const ResetDevice = ({ device }) => {
  const dispatch = useDispatch();
  const payload = {
    id: device.id,
    status: 1,
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const tst = toast.loading("Loading...");

    dispatch(FactoryResetDevice(payload)).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.update(tst, {
          render: "Device reset successfully!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeButton: true,
        });
      } else {
        toast.dismiss(tst);
      }
    });
  };
  return (
    <Dialog modalType="non-modal">
      <DialogTrigger disableButtonEnhancement>
        <Tooltip withArrow content={"Factory Reset Device"}>
          <Button icon={<i className="las la-cogs"></i>}></Button>
        </Tooltip>
      </DialogTrigger>
      <DialogSurface aria-describedby={undefined}>
        <DialogBody>
          <DialogTitle>Factory Reset Device</DialogTitle>
          <DialogContent>
            <form onSubmit={onSubmit}>
              <div className="d-flex flex-column align-items-center">
                <Avatar
                  icon={<i className="las la-cogs"></i>}
                  shape="square"
                  size={120}
                ></Avatar>
                <Subtitle1 className="mt-3">Are you sure?</Subtitle1>
                <Subtitle2>You want to factory reset this device</Subtitle2>

                <Button className="mt-3" type="submit" appearance="primary">
                  Reset
                </Button>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Close</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default ResetDevice;
