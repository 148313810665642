import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  Button,
  Field,
  Input,
} from "@fluentui/react-components";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AddSystemApp, UploadAPK } from "../../features/applicationSlice";

function AddSystemAppModal() {
  const dispatch = useDispatch();

  const [payload, setPayload] = useState({
    name: null,
    package_name: null,
    version: "0",
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    setPayload({ ...payload, [name]: value });
  };


  const onSubmit = (e) => {
    e.preventDefault();

    if (!payload.name || !payload.package_name) {
      toast.warning("All fields are required!");
      return;
    }

    const tst = toast.loading("Loading...");
    

    dispatch(AddSystemApp(payload)).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.update(tst, {
          render: "System app added successfully!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeButton: true,
        });
      } else {
        toast.dismiss(tst);
      }
    });
  };

  return (
    <Dialog modalType="non-modal">
      <DialogTrigger disableButtonEnhancement>
        <Button icon={<i className="la la-android"></i>}>Add System App</Button>
      </DialogTrigger>
      <DialogSurface aria-describedby={undefined}>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <DialogTitle>Add System App</DialogTitle>
            <DialogContent>
              <Field
                label="Application Name"
                required
                size="large"
                className="form-group"
              >
                <Input
                  value={payload.name}
                  name="name"
                  onChange={onChangeInput}
                />
              </Field>
              <Field
                label="Package Name"
                required
                size="large"
                className="form-group"
              >
                <Input
                  value={payload.package_name}
                  name="package_name"
                  onChange={onChangeInput}
                />
              </Field>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Close</Button>
              </DialogTrigger>
              <Button type="submit" appearance="primary">
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
}

export default AddSystemAppModal;
