export const APK_ACTION = {
    1: "Install",
    2: "Uninstall",
    3: "Hide",
    4: "Allow",
    5: "Block"
};

export const ACTION_COLOR = {
  1: "brand",
  2: "danger",
  3: "informative",
  4: "success",
  5: "important",
};

export const USER_ROLES = {
  1: "Admin",
  2: "Manager",
};

export const USER_ROLES_COLOR = {
  1: "success",
  2: "warning",
};