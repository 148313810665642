import { Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Tooltip, 
Button,
Avatar,
Subtitle1,
Subtitle2,
DialogActions
} from '@fluentui/react-components';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { LockDevice } from '../../features/deviceSlice';

const LockOrUnlockDevice = ({device}) => {
  const dispatch = useDispatch();
  const payload = {
    id : device.id,
    status: +!device.device_lock
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const tst = toast.loading("Loading...");

    dispatch(LockDevice(payload)).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
          toast.update(tst, {
            render: payload.status
              ? "Device locked successfully!"
              : "Device unlocked successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
          });
        } else {
          toast.dismiss(tst);
        }
  });
  
  }
  return (
    <Dialog modalType="non-modal">
      <DialogTrigger disableButtonEnhancement>
        <Tooltip
          withArrow
          content={device.device_lock ? "Unlock Device" : "Lock Device"}
        >
          <Button
            icon={
              device.device_lock ? (
                <i className="las la-unlock"></i>
              ) : (
                <i className="las la-lock"></i>
              )
            }
          ></Button>
        </Tooltip>
      </DialogTrigger>
      <DialogSurface aria-describedby={undefined}>
        <DialogBody>
          <DialogTitle>
            {device.device_lock ? "Unlock Device" : "Lock Device"}
          </DialogTitle>
          <DialogContent>
            <form onSubmit={onSubmit}>
              <div className="d-flex flex-column align-items-center">
                <Avatar
                  icon={
                    device.device_lock ? (
                      <i className="las la-unlock"></i>
                    ) : (
                      <i className="las la-lock"></i>
                    )
                  }
                  shape="square"
                  size={120}
                ></Avatar>
                <Subtitle1 className="mt-3">Are you sure?</Subtitle1>
                <Subtitle2>{`You want to ${
                  device.device_lock ? "unlock" : "lock"
                } this device.`}</Subtitle2>

                <Button className="mt-3" type="submit" appearance="primary">
                  {device.device_lock ? "Unlock" : "Lock"}
                </Button>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Close</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

export default LockOrUnlockDevice;