import React from "react";

/// React router dom
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";

//Auth
import {AuthProvider, RequireAuth} from "react-auth-kit"

/// Css
import "./index.css";
import "./chart.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

// Home
import Home from "./components/Home";


//User Management
import User from "./components/userManagement/user";

//Group Management
import GroupManagement from "./components/GroupManagement";

//Configuration Management
import ConfigurationManagement from "./components/ConfigurationManagement";

//Device Management
import DeviceManagement from "./components/DeviceManagement";

//App Management
import AppManagement from "./components/AppManagement";

//Policy Management
import PolicyManagement from "./components/PolicyManagement";

//Devices Location
import DevicesLocation from "./components/DevicesLocation";



/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import ForgotPassword from "./pages/ForgotPassword";


/// Deshboard

export default function Markup() {

   const routes = [
     // //Home
     { url: "", component: <Home /> },

     // //User Managemant
     { url: "manage-users", component: <User /> },

     //Group Management
     { url: "manage-groups", component: <GroupManagement /> },

     //Configuration Management
     { url: "manage-configurations", component: <ConfigurationManagement /> },

     //Device Management
     { url: "manage-devices", component: <DeviceManagement /> },
     //App Management
     { url: "manage-applications", component: <AppManagement /> },
     //Policy Management
     { url: "manage-policies", component: <PolicyManagement /> },
     //Devices Location
     { url: "devices-location", component: <DevicesLocation /> },
     //Common Pages
     { url: "page-register", component: <Registration /> },
     { url: "page-lock-screen", component: <LockScreen /> },
     { url: "page-error-400", component: <Error400 /> },
     { url: "page-error-403", component: <Error403 /> },
     { url: "page-error-500", component: <Error500 /> },
     { url: "page-error-503", component: <Error503 /> },
     { url: "page-forgot-password", component: <ForgotPassword /> },
   ];

   function getCookie(key) {
     var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
     return b ? b.pop() : JSON.stringify({"role_id": 1});
   }

   function replaceEncodedCharacters(encodedString) {
  
  let decodedString = encodedString.replace(/%22/g, '"');

  decodedString = decodedString.replace(/%2C/g, ',');

  return decodedString;
}

   const auth = JSON.parse(replaceEncodedCharacters(getCookie("_auth_state"))) ?? null;

   const role = auth.role_id;

  
   const managerPages = [
    "",
     "manage-configurations",
     "manage-devices",
     "devices-location",
   ];

   return (
     <AuthProvider
       authType={"cookie"}
       authName={"_auth"}
       cookieDomain={window.location.hostname}
       cookieSecure={true}
     >
       <Router>
         <Routes>
           {routes
             .filter((item) => {
               return role === 2
                 ? managerPages.includes(item.url)
                 : item;
             })
             .map((data, i) => (
               <Route
                 key={i}
                 exact
                 path={`/${data.url}`}
                 element={
                   <RequireAuth loginPath="/page-login">
                     <div id="main-wrapper" className="show">
                       <Nav />
                       <div className="content-body">
                         <div className="container-fluid">{data.component}</div>
                       </div>
                       {/* <Footer /> */}
                     </div>
                   </RequireAuth>
                 }
               />
             ))}
           <Route exact path={`/page-login`} element={<Login />} />
           <Route path="*" element={<Error404 />} />
         </Routes>
       </Router>
     </AuthProvider>
   );
};


