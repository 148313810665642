import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addConfiguration, assignApp, configupdateApp, getConfigApps, getConfigurationList, updateConfiguration, updatePassword, updateQrcode } from "../api";
import { toast } from "react-toastify";

const initialState = {
  configurations: [],
  configApps: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const GetConfigurationList = createAsyncThunk(
  "configuration/getList",
  async (pagination, thunkAPI) => {
    try {
      return await getConfigurationList(pagination);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const CreateConfiguration = createAsyncThunk(
  "configuration/create",
  async (data, thunkAPI) => {
    try {
      return await addConfiguration(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const UpdateConfiguration = createAsyncThunk(
  "configuration/update",
  async (data, thunkAPI) => {
    try {
      return await updateConfiguration(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const UpdatePassword = createAsyncThunk(
  "configuration/updatePassword",
  async (data, thunkAPI) => {
    try {
      return await updatePassword(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const UpdateQrcode = createAsyncThunk(
  "configuration/updateQr",
  async (data, thunkAPI) => {
    try {
      return await updateQrcode(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const AssignApp = createAsyncThunk(
  "configuration/assignApp",
  async (data, thunkAPI) => {
    try {
      return await assignApp(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetConfigApps = createAsyncThunk(
  "configuration/getApp",
  async (data, thunkAPI) => {
    try {
      return await getConfigApps(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const ConfigUpdateApp = createAsyncThunk(
  "configuration/updateApp",
  async (data, thunkAPI) => {
    try {
      return await configupdateApp(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const ConfigurationSlice = createSlice({
  name: "ConfigurationSlice",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetConfigurationList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetConfigurationList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.configurations = action.payload;
      })
      .addCase(GetConfigurationList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(CreateConfiguration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateConfiguration.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.configurations.push(action.payload);
      })
      .addCase(CreateConfiguration.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(UpdateConfiguration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpdateConfiguration.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.configurations = state.configurations.map((conf) =>
          conf.id === action.payload.id
            ? { ...conf, name: action.payload.name }
            : conf
        );
      })
      .addCase(UpdateConfiguration.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(UpdatePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpdatePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(UpdatePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(UpdateQrcode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpdateQrcode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.configurations = state.configurations.map((conf) =>
          conf.id === action.payload.id
            ? { ...conf, qr_code: action.payload.qr_code }
            : conf
        );
      })
      .addCase(UpdateQrcode.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(AssignApp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AssignApp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(AssignApp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(GetConfigApps.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetConfigApps.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.configApps = action.payload;
      })
      .addCase(GetConfigApps.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(ConfigUpdateApp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ConfigUpdateApp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(ConfigUpdateApp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const { reset } = ConfigurationSlice.actions;
export default ConfigurationSlice.reducer;
