import React, { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  Input,
  Button,
  Field,
  Tooltip,
} from "@fluentui/react-components";
import { useDispatch } from "react-redux";
import { CreateConfiguration, UpdateConfiguration } from "../../features/configurationSlice";
import { toast } from "react-toastify";

function AddOrUpdateConfiguration({ data }) {
  const dispatch = useDispatch();

  const [conf, setConf] = useState({
    id: data?.id ?? "",
    name: data?.name ?? "",
    password: "",
    qr_code: generateRandomString(50)
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    setConf({ ...conf, [name]: value });
  };

  function generateRandomString(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const onSubmit = (e) => {
    e.preventDefault();

    const tst = toast.loading("Loading...");

    if(data){
        dispatch(UpdateConfiguration({id: conf.id, name: conf.name})).then(response => {
             if (response.meta.requestStatus === "fulfilled") {
               toast.update(tst, {
                 render: "Configuration updated successfully!",
                 type: "success",
                 isLoading: false,
                 autoClose: 3000,
                 closeButton: true,
               });
             } else {
               toast.dismiss(tst);
             }
        });
    }else{
        dispatch(CreateConfiguration(conf)).then(response => {
            if (response.meta.requestStatus === "fulfilled") {
              toast.update(tst, {
                render: "Configuration created successfully!",
                type: "success",
                isLoading: false,
                autoClose: 3000,
                closeButton: true,
              });
            } else {
              toast.dismiss(tst);
            }
        })
    }

  };

  return (
    <Dialog modalType="non-modal">
      <DialogTrigger disableButtonEnhancement>
        {data ? (
          <Tooltip withArrow content={"Edit"}>
            <Button icon={<i className="las la-pen"></i>}></Button>
          </Tooltip>
        ) : (
          <Button appearance="primary" icon={<i className="las la-tools"></i>}>
            Add
          </Button>
        )}
      </DialogTrigger>
      <DialogSurface aria-describedby={undefined}>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <DialogTitle>
              {data ? "Update Configuration" : "Add Configuration"}
            </DialogTitle>
            <DialogContent>
              <Field label="Name" required size="large" className="form-group">
                <Input value={conf.name} name="name" onChange={onChangeInput} />
              </Field>
              {!data && <Field
                label="Password"
                required
                size="large"
                className="form-group"
              >
                <Input
                  value={conf.password}
                  name="password"
                  type="password"
                  onChange={onChangeInput}
                />
              </Field>}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Close</Button>
              </DialogTrigger>
              <Button type="submit" appearance="primary">
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
}

export default AddOrUpdateConfiguration;
