import React from 'react';
import { MessageBar, MessageBarBody, MessageBarActions, Button, MessageBarTitle } from '@fluentui/react-components';

const CustomMessageBar = ({intent, title, message, action}) => {
  return (
    <MessageBar intent={intent}>
      <MessageBarBody>
        <MessageBarTitle>{title}</MessageBarTitle>
        {message}
      </MessageBarBody>
      <MessageBarActions
        containerAction={
          <Button
            aria-label="dismiss"
            appearance="transparent"
            icon={<i className='las la-1x la-times'></i>}
          />
        }
      >
        <Button onClick={() => action()}>Retry</Button>
      </MessageBarActions>
    </MessageBar>
  );
}

export default CustomMessageBar;