import React, { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  Input,
  Button,
  Field,
  Textarea,
  Dropdown,
  Option,
  Persona,
  Avatar,
  Body1,
  Tooltip,
} from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { CreateDevice, UpdateDevice } from "../../features/deviceSlice";
import { toast } from "react-toastify";
import { useAuthUser } from "react-auth-kit";

function AddOrUpdateDevice({ data }) {
  const dispatch = useDispatch();
  const {configurations} = useSelector(state => state.configuration);
  const {groups} = useSelector(state => state.group);
 

  const [device, setDevice] = useState({
    id: data?.id ?? "",
    description: data?.description ?? "",
    imei: data?.imei ?? "",
    group_id: data?.group_id ?? "",
    configuration_id: data?.configuration_id ?? ""
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    setDevice({ ...device, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const tst = toast.loading("Loading...");

    if (data) {
      dispatch(UpdateDevice(device)).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          toast.update(tst, {
            render: "Device updated successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
          });
        } else {
          toast.dismiss(tst);
        }
      });
    } else {
      dispatch(CreateDevice(device)).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          toast.update(tst, {
            render: "Device created successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
          });
        } else {
          toast.dismiss(tst);
        }
      });
    }
  };

  return (
    <Dialog modalType="non-modal">
      <DialogTrigger disableButtonEnhancement>
        {data ? (
          <Tooltip withArrow content={"Edit"}>
            <Button icon={<i className="las la-pen"></i>}></Button>
          </Tooltip>
        ) : (
          <Button appearance="primary" icon={<i className="las la-mobile"></i>}>
            Add
          </Button>
        )}
      </DialogTrigger>
      <DialogSurface aria-describedby={undefined}>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <DialogTitle>{data ? "Update Group" : "Add Group"}</DialogTitle>
            <DialogContent>
              <Field
                label="Description"
                required
                size="large"
                className="form-group"
              >
                <Textarea
                  value={device.description}
                  name="description"
                  onChange={onChangeInput}
                />
              </Field>
              <Field label="IMEI" required size="large" className="form-group">
                <Input
                  value={device.imei}
                  name="imei"
                  onChange={onChangeInput}
                />
              </Field>
              {!data && <Field label="Group" required size="large" className="form-group">
                <Dropdown
                  onOptionSelect={(e, d) =>
                    setDevice({ ...device, group_id: d.optionValue })
                  }
                >
                  {groups.map((gr) => (
                    <Option text={gr.name} value={gr.id}>
                      <Avatar color="colorful" name={gr.name} />
                      <Body1>{gr.name}</Body1>
                    </Option>
                  ))}
                </Dropdown>
              </Field>}
              <Field
                label="Configuration"
                required
                size="large"
                className="form-group"
              >
                <Dropdown
                 defaultSelectedOptions={[device.configuration_id]}
                  value={configurations.filter(conf => conf.id === device.configuration_id).map(conf => conf.name)}
                  onOptionSelect={(e, d) =>
                    setDevice({ ...device, configuration_id: d.optionValue })
                  }
                >
                  {configurations.map((conf) => (
                    <Option text={conf.name} value={conf.id}>
                      <Avatar
                        icon={<i className="las la-tools"></i>}
                        title={conf.name}
                        shape="square"
                      />
                      <Body1>{conf.name}</Body1>
                    </Option>
                  ))}
                </Dropdown>
              </Field>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Close</Button>
              </DialogTrigger>
              <Button type="submit" appearance="primary">
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
}

export default AddOrUpdateDevice;
