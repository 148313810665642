import React, { Fragment } from 'react';
import { Row, Nav, Tab, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Bar, Pie, Line, Doughnut } from 'react-chartjs-2';
import { Chart } from "chart.js/auto";
import CountUp from 'react-countup';


function Home() {
   
    const barData = {
      defaultFontFamily: "Poppins",
      labels: ["Devices Summary"],
      datasets: [
        {
          label: "Total Devices",
          data: [100],
          borderColor: "#2F4CDD",
          borderWidth: "0",
          backgroundColor: "#2F4CDD",
        },
        {
          label: "Enrolled Devices",
          data: [60],
          borderColor: "rgb(255, 184, 0)",
          borderWidth: "0",
          backgroundColor: "rgb(255, 184, 0)",
        },
        {
          label: "Online Devices",
          data: [30],
          borderColor: "#15763f",
          borderWidth: "0",
          backgroundColor: "#15763f",
        },
        {
          label: "Offline Devices",
          data: [30],
          borderColor: "#BF0711",
          borderWidth: "0",
          backgroundColor: "#BF0711",
        },
      ],
    };

      const barDataProvince = {
         defaultFontFamily: "Poppins",
         labels: ["ISLAMABAD", "PUNJAB", "SINDH", "BALOCHISTAN", "AZAD KASHMIR", "GILGIT BALTISTAN"],
         datasets: [
            {
               label: "User Summary By Province",
               data: [1400, 2500, 1800, 1100, 2500, 1050],
               borderColor: "#15763f",
               borderWidth: "0",
               backgroundColor: "#15763f",
            },
            
         ],
      };

const pieData = {
  labels: [
    "Total Devices",
    "Enrolled Devices",
    "Online Devices",
    "Offline Devices",
  ],
  datasets: [
    {
      label: "Devices Summary",
      data: [100, 60, 30, 30],
      backgroundColor: ["#2F4CDD", "rgb(255, 184, 0)", "#15763f", "#BF0711"],
      borderColor: ["#2F4CDD", "rgb(255, 184, 0)", "#15763f", "#BF0711"],
      borderWidth: 1,
    },
  ],
};

const netDistributionProvince = {
  labels: ["ISLAMABAD", "PUNJAB", "SINDH", "BALOCHISTAN", "AZAD KASHMIR", "GILGIT BALTISTAN"],
  datasets: [
    {
      label: 'Net Distribution By Province',
      data: [1100, 1300, 1450, 1050, 900, 700],
      backgroundColor: [
        '#15763f',
        'rgb(255, 184, 0)',
        '#2F4CDD',
        '#BF0711',
        '#108043',
        '#50248F',
      ],
      borderColor: [
        '#15763f',
        'rgb(255, 184, 0)',
        '#2F4CDD',
        '#BF0711',
        '#108043',
        '#50248F',
      ],
      borderWidth: 1,
    },
  ],
};

const netBarData = {

   labels: [
            "06-09-2023",
            "07-09-2023",
            "08-09-2023",
            "09-09-2023",
            "10-09-2023",
            "11-09-2023",
            "12-09-2023",
         ],

   datasets: [
            {
               label: "Monthly Nets Distribution",
               data: [1400, 1300, 1800, 1100, 1800, 1050, 850],
               borderColor: "#15763f",
               borderWidth: "0",
               backgroundColor: "#15763f",
            },
            
         ],

};

const lineData = {
         labels: [
            "06-09-2023",
            "07-09-2023",
            "08-09-2023",
            "09-09-2023",
            "10-09-2023",
            "11-09-2023",
            "12-09-2023",
         ],
         datasets: [
            {
         label: "Registered User",
         data: [1200, 1100, 1050, 1300, 1450, 1500, 1670],
         borderColor: "#15763f",
         borderWidth: "2",
         backgroundColor: "rgba(8, 78, 138, .7)",
      },
      {
         
         label: "Distributed Nets",
         data: [400, 600, 1200, 1300, 1500, 1400, 1150],
         borderColor: "rgb(255, 184, 0)",
         borderWidth: "2",
         backgroundColor: "rgba(255, 184, 0, .7)",
      },
      {
         label: "Generated QR",
         data: [900, 1000, 1250, 1000, 1150, 1200, 1370],
         borderColor: "#2F4CDD",
         borderWidth: "2",
         backgroundColor: "#2F4CDD",
      }
         ],
      };

      const options = {
   legend: false,
   scales: {
      yAxes: [
         {
            ticks: {
               beginAtZero: true,
               max: 100,
               min: 0,
               stepSize: 20,
               padding: 10,
            },
         },
      ],
      xAxes: [
         {
            ticks: {
               padding: 5,
            },
         },
      ],
   },
};


  return (
    <Fragment>
        <Row>
            <div className="col-xl-4 col-lg-6 col-sm-6">
               <div className="widget-stat card bg-primary">
                  <div className="card-body p-4">
                     <div className="media">
                        <span className="mr-3">
                           <i className="flaticon-381-list"></i>
                        </span>
                        <div className="media-body text-white text-right">
                           <p className="mb-1">Total Devices</p>
                           <h3 className="text-white"><CountUp
                              start={0}
                              end={100}
                           /></h3>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-sm-6">
               <div className="widget-stat card bg-primary">
                  <div className="card-body p-4">
                     <div className="media">
                        <span className="mr-3">
                           <i className="flaticon-381-list-1"></i>
                        </span>
                        <div className="media-body text-white text-right">
                           <p className="mb-1">Enrolled Devices</p>
                           <h3 className="text-white"><CountUp
                              start={0}
                              end={60}
                           /></h3>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-sm-6">
               <div className="widget-stat card bg-primary">
                  <div className="card-body p-4">
                     <div className="media">
                        <span className="mr-3">
                           <i className="flaticon-381-list-1"></i>
                        </span>
                        <div className="media-body text-white text-right">
                           <p className="mb-1">Online Devices</p>
                           <h3 className="text-white"><CountUp
                              start={0}
                              end={30}
                           /></h3>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
        </Row>

        <Row>
            <div className="col-xl-4 col-lg-6 col-sm-6">
               <div className="widget-stat card bg-primary">
                  <div className="card-body p-4">
                     <div className="media">
                        <span className="mr-3">
                           <i className="flaticon-381-background"></i>
                        </span>
                        <div className="media-body text-white text-right">
                           <p className="mb-1">Offline Devices</p>
                           <h3 className="text-white"><CountUp
                              start={0}
                              end={30}
                           /></h3>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-sm-6">
               <div className="widget-stat card bg-primary">
                  <div className="card-body p-4">
                     <div className="media">
                        <span className="mr-3">
                           <i className="flaticon-381-background"></i>
                        </span>
                        <div className="media-body text-white text-right">
                           <p className="mb-1">Total Apps</p>
                           <h3 className="text-white"><CountUp
                              start={0}
                              end={50}
                           /></h3>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <div className="col-xl-4 col-lg-6 col-sm-6">
               <div className="widget-stat card bg-primary">
                  <div className="card-body p-4">
                     <div className="media">
                        <span className="mr-3">
                           <i className="flaticon-381-background"></i>
                        </span>
                        <div className="media-body text-white text-right">
                           <p className="mb-1">Remaining NETS</p>
                           <h3 className="text-white"><CountUp
                              start={0}
                              end={4400}
                           /></h3>
                        </div>
                     </div>
                  </div>
               </div>
            </div> */}
        </Row>

        {/* <Row>
            <div className="col-xl-4 col-lg-6 col-sm-6">
               <div className="widget-stat card bg-primary">
                  <div className="card-body p-4">
                     <div className="media">
                        <span className="mr-3">
                           <i className="flaticon-381-user-9"></i>
                        </span>
                        <div className="media-body text-white text-right">
                           <p className="mb-1">Total Registered Users</p>
                           <h3 className="text-white"><CountUp
                              start={0}
                              end={7000}
                           /></h3>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-sm-6">
               <div className="widget-stat card bg-primary">
                  <div className="card-body p-4">
                     <div className="media">
                        <span className="mr-3">
                           <i className="flaticon-381-user-9"></i>
                        </span>
                        <div className="media-body text-white text-right">
                           <p className="mb-1">Registered Users This Month</p>
                           <h3 className="text-white"><CountUp
                              start={0}
                              end={1200}
                           /></h3>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-sm-6">
               <div className="widget-stat card bg-primary">
                  <div className="card-body p-4">
                     <div className="media">
                        <span className="mr-3">
                           <i className="flaticon-381-user-9"></i>
                        </span>
                        <div className="media-body text-white text-right">
                           <p className="mb-1">Registered Users Last Month</p>
                           <h3 className="text-white"><CountUp
                              start={0}
                              end={1450}
                           /></h3>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
        </Row> */}

        {/* <div className="col-xl-12 col-lg-12">
				<div className="card">
					<div className="card-header pb-0 border-0">
						<div>
							<h4 className="card-title mb-2">Daily Summary</h4>
							<p className="fs-13 mb-0">Lorem ipsum dolor sit amet,consecteture</p>
						</div>
						<div className="dropdown">
							<button type="button" className="btn border btn-rounded text-black dropdown-toggle" data-toggle="dropdown">
								Daily
							</button>
							<div className="dropdown-menu">
								<Link to={"#"} className="dropdown-item" >2020</Link>
								<Link to={"#"} className="dropdown-item" >2019</Link>
								<Link to={"#"} className="dropdown-item" >2018</Link>
							</div>
						</div>
					</div>
					<div className="card-body px-0 px-sm-3">
						
						<div>
							<Line
               data={lineData}
               options={options}
               height={150}
            />
						</div>
					</div>
				</div>
			</div> */}

         {/* <Row>
            <Col lg={8}>
               <div className="card">
					<div className="card-header pb-0 border-0">
						<div>
							<h4 className="card-title mb-2">User Summary By Province</h4>
							<p className="fs-13 mb-0">Lorem ipsum dolor sit amet,consecteture</p>
						</div>
						<div className="dropdown">
							<button type="button" className="btn border btn-rounded text-black dropdown-toggle" data-toggle="dropdown">
								Daily
							</button>
							<div className="dropdown-menu">
								<Link to={"#"} className="dropdown-item" >2020</Link>
								<Link to={"#"} className="dropdown-item" >2019</Link>
								<Link to={"#"} className="dropdown-item" >2018</Link>
							</div>
						</div>
					</div>
					<div className="card-body px-0 px-sm-3">
						<div>
							<Bar data={barDataProvince} height={150} />
						</div>
					</div>
				</div>
            </Col>
            
                <div className="col-xl-4 col-lg-12 col-sm-12">
               <div className="card">
                  <div className="card-header border-0 pb-0">
                     <h2 className="card-title">User Counts By Province</h2>
                  </div>
                  <div className="card-body pb-0">
                     <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                     </p>
                     <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex px-0 justify-content-between">
                           <strong>ISLAMABAD</strong>
                           <span className="mb-0">1400</span>
                        </li>
                        <li className="list-group-item d-flex px-0 justify-content-between">
                           <strong>PUNJAB</strong>
                           <span className="mb-0">2500</span>
                        </li>
                        <li className="list-group-item d-flex px-0 justify-content-between">
                           <strong>SINDH</strong>
                           <span className="mb-0">1800</span>
                        </li>
                        <li className="list-group-item d-flex px-0 justify-content-between">
                           <strong>BALOCHISTAN</strong>
                           <span className="mb-0">1100</span>
                        </li>
                        <li className="list-group-item d-flex px-0 justify-content-between">
                           <strong>AZAD KASHMIR</strong>
                           <span className="mb-0">2500</span>
                        </li>
                        <li className="list-group-item d-flex px-0 justify-content-between">
                           <strong>GILGIT BALTISTAN</strong>
                           <span className="mb-0">1050</span>
                        </li>
                     </ul>
                  </div>
                  <div className="card-footer pt-0 pb-0 text-center">
                     <div className="row">
                        <div className="col-4 pt-3 pb-3 border-right">
                           <h3 className="mb-1 text-primary">7000</h3>
                           <span>Total</span>
                        </div>
                        <div className="col-4 pt-3 pb-3 border-right">
                           <h3 className="mb-1 text-primary">1200</h3>
                           <span>This Month</span>
                        </div>
                        <div className="col-4 pt-3 pb-3">
                           <h3 className="mb-1 text-primary">1450</h3>
                           <span>Last Month</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            
         </Row> */}

         {/* <Row>
        <div className="col-xl-6 col-lg-12">
				<div className="card">
					<div className="card-header pb-0 border-0">
						<div>
							<h4 className="card-title mb-2">Daily Nets Distribution Summary</h4>
							<p className="fs-13 mb-0">Lorem ipsum dolor sit amet,consecteture</p>
						</div>
						<div className="dropdown">
							<button type="button" className="btn border btn-rounded text-black dropdown-toggle" data-toggle="dropdown">
								Daily
							</button>
							<div className="dropdown-menu">
								<Link to={"#"} className="dropdown-item" >2020</Link>
								<Link to={"#"} className="dropdown-item" >2019</Link>
								<Link to={"#"} className="dropdown-item" >2018</Link>
							</div>
						</div>
					</div>
					<div className="card-body px-0 px-sm-3">
						
						<div>
							<Bar data={netBarData} height={300} />
						</div>
					</div>
				</div>
			</div>

            <div className="col-xl-6 col-lg-12">
				<div className="card">
					<Tab.Container defaultActiveKey={{}}>
						<div className="card-header border-0 d-sm-flex d-block">
							<div>
								<h4 className="card-title mb-2">Net Distribution By Province</h4>
								<p className="fs-13 mb-0">Lorem ipsum dolor</p>
							</div>
							<div className="card-action card-tabs mt-3 mt-sm-0 mt-3 mt-sm-0 ">
									<Nav as="ul" className="nav nav-tabs ">
									   
									</Nav>
							</div>
						</div>
						<div className="card-body">
							<Tab.Content className="">
							   <Doughnut data={netDistributionProvince} />
							</Tab.Content>
						</div>
					</Tab.Container>
				</div>
			</div>
        </Row> */}

        <Row>
        <div className="col-xl-6 col-lg-12">
				<div className="card">
					<div className="card-header pb-0 border-0">
						<div>
							<h4 className="card-title mb-2">Devices Summary</h4>
							{/* <p className="fs-13 mb-0">Lorem ipsum dolor sit amet,consecteture</p> */}
						</div>
						{/* <div className="dropdown">
							<button type="button" className="btn border btn-rounded text-black dropdown-toggle" data-toggle="dropdown">
								Daily
							</button>
							<div className="dropdown-menu">
								<Link to={"#"} className="dropdown-item" >2020</Link>
								<Link to={"#"} className="dropdown-item" >2019</Link>
								<Link to={"#"} className="dropdown-item" >2018</Link>
							</div>
						</div> */}
					</div>
					<div className="card-body px-0 px-sm-3">
						{/* <div className="d-flex justify-content-center">
							<div className="text-center px-2 px-md-5">
								<p className="fs-14 mb-1">Consumed</p>
								<h4 className="text-black font-w600 mb-0 fs-22">2.7k</h4>
							</div>
							<div className="text-center px-2 px-md-5">
								<p className="fs-14 mb-1">Remaining</p>
								<h4 className="text-black font-w600 mb-0 fs-22">2.3k</h4>
							</div>
						</div> */}
						<div>
							<Bar data={barData} height={300} />
						</div>
					</div>
				</div>
			</div>

            <div className="col-xl-6 col-lg-12">
				<div className="card">
					<Tab.Container defaultActiveKey={{}}>
						<div className="card-header border-0 d-sm-flex d-block">
							<div>
								<h4 className="card-title mb-2">Devices Summary</h4>
								{/* <p className="fs-13 mb-0">Lorem ipsum dolor</p> */}
							</div>
							<div className="card-action card-tabs mt-3 mt-sm-0 mt-3 mt-sm-0 ">
									<Nav as="ul" className="nav nav-tabs ">
									   
									</Nav>
							</div>
						</div>
						<div className="card-body">
							<Tab.Content className="">
							   <Pie data={pieData} height={150} />
							</Tab.Content>
						</div>
					</Tab.Container>
				</div>
			</div>
        </Row>
    </Fragment>
  )
}

export default Home;