import React from "react";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./jsx/app/store";
import ReactResizeDetector from 'react-resize-detector';
import { createRoot } from "react-dom/client";
import { FluentProvider, createLightTheme } from '@fluentui/react-components';

 const customTheme = { 
  10: "#010402",
  20: "#0F1C12",
  30: "#142F1C",
  40: "#163D23",
  50: "#174B2A",
  60: "#175A31",
  70: "#176938",
  80: "#1A7841",
  90: "#388552",
  100: "#4F9264",
  110: "#659F75",
  120: "#7BAC87",
  130: "#90B99A",
  140: "#A5C7AD",
  150: "#BAD4C0",
  160: "#CFE1D3"
};

const lightTheme = {
   ...createLightTheme(customTheme), 
};

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
   <React.StrictMode>
      <Provider store={store}>
      <FluentProvider theme={lightTheme}>
      <ReactResizeDetector refreshMode="debounce" refreshRate={0}>
         
            <App />
         
      </ReactResizeDetector>
      </FluentProvider>
      </Provider>
   </React.StrictMode>,
);