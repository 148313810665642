import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/authSlice';
import userReducer from '../features/userSlice';
import groupReducer from '../features/groupSlice';
import configurationReducer from '../features/configurationSlice';
import deviceReducer from '../features/deviceSlice';
import  AppReducer  from '../features/applicationSlice';
import policyReducer from '../features/policySlice';
import  LocationReducer  from '../features/deviceLocationSlice';


export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    group: groupReducer,
    configuration: configurationReducer,
    device: deviceReducer,
    app: AppReducer,
    policy: policyReducer,
    location: LocationReducer
  },
});