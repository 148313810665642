import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addPolicy, assignPolicy, getConfigOrDevicePolicy, getPolicyList, updatePolicy } from "../api";
import { toast } from "react-toastify";

const initialState = {
  policies: [],
  dPolicy: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const GetPolicyList = createAsyncThunk(
  "policy/getList",
  async (pagination, thunkAPI) => {
    try {
      return await getPolicyList(pagination);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const CreatePolicy = createAsyncThunk(
  "policy/create",
  async (data, thunkAPI) => {
    try {
      return await addPolicy(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const UpdatePolicy = createAsyncThunk(
  "policy/update",
  async (data, thunkAPI) => {
    try {
      return await updatePolicy(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetConfigOrDevicePolicy = createAsyncThunk(
  "policy/configOrDevice",
  async (data, thunkAPI) => {
    try {
      return await getConfigOrDevicePolicy(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const AssignPolicy = createAsyncThunk(
  "policy/assign",
  async (data, thunkAPI) => {
    try {
      return await assignPolicy(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const PolicySlice = createSlice({
  name: "PolicySlice",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetPolicyList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetPolicyList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.policies = action.payload;
      })
      .addCase(GetPolicyList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(CreatePolicy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreatePolicy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.policies.push(action.payload);
      })
      .addCase(CreatePolicy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(UpdatePolicy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpdatePolicy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.policies = state.policies.map((policy) =>
          policy.id === action.payload.id
            ? { ...policy, name: action.payload.name }
            : policy
        );
      })
      .addCase(UpdatePolicy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(GetConfigOrDevicePolicy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetConfigOrDevicePolicy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.dPolicy = action.payload;
      })
      .addCase(GetConfigOrDevicePolicy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(AssignPolicy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AssignPolicy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(AssignPolicy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const { reset } = PolicySlice.actions;
export default PolicySlice.reducer;
