import AddOrUpdateGroup from "./modal";
import {
  Avatar,
  TableBody,
  TableCell,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "@fluentui/react-components";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import UpdatePasswordModal from "./password";
import UpdateQrcodeModal from "./qrcode";
import AssignAppModal from "./assignapp";
import ConfigurationApps from "./ConfigurationApps";
import AssignPolicyModal from "../PolicyManagement/assignPolicy";
import { useAuthUser } from "react-auth-kit";

function ConfigurationTable({ data }) {
  const auth = useAuthUser();
  return (
      <Table responsive="sm" striped>
        <TableHeader>
          <TableRow>
            <TableHeaderCell scope="col">Name</TableHeaderCell>
            {/* <TableHeaderCell scope="col">QrCode</TableHeaderCell> */}
            <TableHeaderCell scope="col">Created At</TableHeaderCell>
            <TableHeaderCell scope="col">Last Update</TableHeaderCell>
            <TableHeaderCell scope="col">Action</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <TableCellLayout
                  media={
                    <Avatar
                      shape="square"
                      aria-label={item.name}
                      icon={<i className="las la-1x la-tools"></i>}
                    />
                  }
                >
                  {item.name}
                </TableCellLayout>
              </TableCell>
              {/* <TableCell>
                <TableCellLayout
                  media={<i className="las la-1x la-qrcode"></i>}
                >
                  {item.qr_code}
                </TableCellLayout>
              </TableCell> */}
              <TableCell>
                <TableCellLayout
                  media={<i className="las la-1x la-plus-square"></i>}
                >
                  <Moment format="LLL">{item.created_at}</Moment>
                </TableCellLayout>
              </TableCell>
              <TableCell>
                <TableCellLayout media={<i className="las la-1x la-edit"></i>}>
                  <Moment format="LLL">{item.modified_at}</Moment>
                </TableCellLayout>
              </TableCell>
              <TableCell>
                <TableCellLayout>
                  {auth().role_id === 1 && <AddOrUpdateGroup data={item} /> }
                  {auth().role_id === 1 && <UpdatePasswordModal id={item.id} /> }
                  {auth().role_id === 1 && <UpdateQrcodeModal id={item.id} qr_code={item.qr_code} /> }
                  <AssignAppModal configuration_id={item.id} />
                  <ConfigurationApps configuration_id={item.id} />
                  <AssignPolicyModal id={item.id} />
                </TableCellLayout>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
  );
}

export default ConfigurationTable;
