
import { USER_ROLES, USER_ROLES_COLOR } from "../../constants/constants";
import AddOrUpdateUser from "./modal";
import { Avatar, Badge, Table,
   TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, Tooltip} from "@fluentui/react-components";



function UserTable({data}) {
  

  return (
    <>
      <div className="table-responsive">
        <Table sortable className="table table-striped">
          <TableHeader>
            <TableRow>
              <TableHeaderCell scope="col">Name</TableHeaderCell>
              <TableHeaderCell scope="col">Username</TableHeaderCell>
              <TableHeaderCell scope="col">Phone</TableHeaderCell>
              <TableHeaderCell scope="col">Email</TableHeaderCell>
              <TableHeaderCell scope="col">Role</TableHeaderCell>
              <TableHeaderCell scope="col">Action</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <Tooltip
                    withArrow
                    content={item.active ? "Active" : "Inactive"}
                    relationship="label"
                  >
                    <TableCellLayout
                      media={
                        <Avatar
                          aria-label={item.full_name}
                          name={item.full_name}
                          badge={{
                            status: item.active ? "available" : "busy",
                          }}
                        />
                      }
                    >
                      {item.full_name}
                    </TableCellLayout>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <TableCellLayout>{item.username}</TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellLayout
                    media={<i className="las la-1x la-phone"></i>}
                  >
                    {item.phone}
                  </TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellLayout
                    media={<i className="las la-1x la-envelope"></i>}
                  >
                    {item.email}
                  </TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellLayout>
                    <Badge appearance="filled" color={USER_ROLES_COLOR[item.role_id]}>
                      {USER_ROLES[item.role_id]}
                    </Badge>
                  </TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellLayout>
                    <AddOrUpdateUser data={item} />
                  </TableCellLayout>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
}

export default UserTable;