import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { login } from '../api';
import { toast } from 'react-toastify';


const initialState = {
    authData: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}



export const AuthLogin = createAsyncThunk('auth/login', async (loginData, thunkAPI) => {
    try{
        
        return await login(loginData)
    }catch (error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message
        || error.toString();

        return thunkAPI.rejectWithValue(message);
    }
});


export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers : (builder) => {
        builder
        .addCase(AuthLogin.pending, (state) => {
            state.isLoading = true
        })
        .addCase(AuthLogin.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.authData = action.payload
            toast.success("Login successfully!");
        })
        .addCase(AuthLogin.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.isSuccess = false
            state.message = action.payload
            toast.error(action.payload);
        })
        
    }
});

export const {reset} = authSlice.actions;
export default authSlice.reducer;