import React, { useState , Fragment } from "react";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import logoText from "../../images/logo-text.png";
import { AuthLogin } from "../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomSpinner from "../components/spinner";
import { Field, Input, Button } from "@fluentui/react-components";


const Login = () => {
   const signIn = useSignIn();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const {isLoading} = useSelector(state => state.auth);

   const [data, setData] = useState({
    username: "",
    password: "",
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target

    setData({ ...data, [name]: value });
  }
   
   const submitHandler = async (e) => {
      e.preventDefault();
      
      dispatch(AuthLogin(data)).then((response) => {
         
       if (response.meta.requestStatus === "fulfilled") {
         signIn({
           token: response.payload.token,
           expiresIn: 540,
           tokenType: "Bearer",
           authState: response.payload,
         });

         navigate("/");
         
         window.location.reload();
       }
     
      });

   };

  
   return (
      isLoading ? <CustomSpinner label={"Logging in ..."} /> :<Fragment>
      <div className="row justify-content-center h-100 align-items-center h-80 bg-primary full-height">
         <div className="col-md-4">
         <div className="brand-logo mb-5 d-flex justify-content-center align-items-center">
            <img className="logo-abbr mr-3" src={logo} alt="" />
            <img className="brand-title" src={logoText} alt="" />
         </div>
            <div className="authincation-content">
               <div className="row no-gutters">
                  <div className="col-xl-12">
                     <div className="auth-form">
                        <h4 className="text-center mb-4 "> Sign in your account  </h4>
                        <form onSubmit={submitHandler}  >
                        <Field label="Username" required size="large" className="form-group">
                           <Input name="username" onChange={onChangeInput}/>
                        </Field>
                        <Field label="Password" required size="large" className="form-group">
                           <Input name="password" type="password" onChange={onChangeInput}/>
                        </Field>
                        <div className="form-group">
                        <Button className="btn-block" appearance="primary" type="submit" size="large">Sign in</Button>
                        </div>
                           {/* <div className="form-group">    <label className="mb-1 ">  <strong>Username</strong> </label>
                              <input type="text" name="username" className="form-control" required onChange={onChangeInput}   />
                           </div> */}
                           {/* <div className="form-group">
                              <label className="mb-1 "> <strong>Password</strong>  </label>
                              <input type="password" name="password" className="form-control" required onChange={onChangeInput}  />
                           </div> */}
                           {/* <div className="form-row d-flex justify-content-between mt-4 mb-2">
                              <div className="form-group">
                                 <div className="custom-control custom-checkbox ml-1 ">
                                    <input  type="checkbox" className="custom-control-input"   id="basic_checkbox_1"  />
                                    <label    className="custom-control-label" htmlFor="basic_checkbox_1"  >  Remember my preference </label>
								</div>
                              </div>
                              <div className="form-group">
                                 <Link  className=""  to="/page-forgot-password"  > Forgot Password?  </Link>
                              </div>
                           </div> */}
                           {/* <div className="text-center">
                              <button  type="submit"  className="btn btn-primary btn-block" > Sign Me In </button>
                           </div> */}
                        </form>
                        {/* <div className="new-account mt-3">
                           <p className=""> Don't have an account?{" "}   <Link className="text-primary" to="/page-register">  Sign up   </Link>  </p>
                        </div> */}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      </Fragment>
   );
};

export default Login;
