import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addGroup, getGroupList, updateGroup} from "../api";
import { toast } from "react-toastify";

const initialState = {
  groups: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const GetGroupList = createAsyncThunk(
  "group/getList",
  async (pagination, thunkAPI) => {
    try {
      return await getGroupList(pagination);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const CreateGroup = createAsyncThunk(
  "group/create",
  async (data, thunkAPI) => {
    try {
      return await addGroup(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const UpdateGroup = createAsyncThunk(
  "group/update",
  async (data, thunkAPI) => {
    try {
      return await updateGroup(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GroupSlice = createSlice({
  name: "GroupSlice",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetGroupList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetGroupList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.groups = action.payload;
      })
      .addCase(GetGroupList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(CreateGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.groups.push(action.payload);
        
      })
      .addCase(CreateGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(UpdateGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpdateGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.groups = state.groups.map((gr) =>
          gr.id === action.payload.id
            ? { ...gr, name: action.payload.name }
            : gr
        );
      })
      .addCase(UpdateGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })
  },
});

export const { reset } = GroupSlice.actions;
export default GroupSlice.reducer;
