import React, { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  Input,
  Button,
  Field,
  Tooltip,
} from "@fluentui/react-components";
import { useDispatch} from "react-redux";
import { UpdatePassword } from "../../features/configurationSlice";
import { toast } from "react-toastify";

function UpdatePasswordModal({ id }) {
  const dispatch = useDispatch();

  const [conf, setConf] = useState({
    id: id,
    password: "",
    confirmPassword: ""
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    setConf({ ...conf, [name]: value });
  };


  const onSubmit = (e) => {
    e.preventDefault();
    
    if(conf.password !== conf.confirmPassword){
        toast.error("Password does not match!");
        return;
    }
    const tst = toast.loading("Updating Configuration Password!");
    dispatch(UpdatePassword(conf)).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          toast.update(tst, {
            render: "Configuration password updated successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true
          });
        }else{
            toast.dismiss(tst);
        }
    });
    
  };

  return (
    <Dialog modalType="non-modal">
      <DialogTrigger disableButtonEnhancement>
        <Tooltip withArrow content={"Update Password"}>
          <Button icon={<i className="las la-lock"></i>}></Button>
        </Tooltip>
      </DialogTrigger>
      <DialogSurface aria-describedby={undefined}>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <DialogTitle>Update Password</DialogTitle>
            <DialogContent>
              <Field
                label="Password"
                required
                size="large"
                className="form-group"
              >
                <Input
                  name="password"
                  type="password"
                  onChange={onChangeInput}
                />
              </Field>
              <Field
                label="Confirm Password"
                required
                size="large"
                className="form-group"
              >
                <Input
                  name="confirmPassword"
                  type="password"
                  onChange={onChangeInput}
                />
              </Field>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Close</Button>
              </DialogTrigger>
              <Button type="submit" appearance="primary">
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
}

export default UpdatePasswordModal;
