import React from 'react'
import { Text, Dropdown, Option } from '@fluentui/react-components';
import { Link } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';

const UsePagination = (data, index, perPage, setPerPage, setIndex) => {
  return (
    <div className="d-flex justify-content-between align-items-center mt-3">
      <div className="dataTables_info d-flex justify-content-center align-items-center">
        <Text weight="medium" truncate className="mt-2">
          Showing {index * perPage + 1} to {index * perPage + perPage} of{" "}
          {index * perPage + perPage} entries
        </Text>
        <Dropdown
          style={{ width: "auto" }}
          className="ml-3"
          defaultValue={"150 per page"}
          onOptionSelect={(e, d) => {
            setPerPage(d.optionValue);
            setIndex(0);
          }}
        >
          <Option value={150}>150 per page</Option>
          <Option value={200}>200 per page</Option>
          <Option value={250}>250 per page</Option>
          <Option value={350}>350 per page</Option>
          <Option value={400}>400 per page</Option>
        </Dropdown>
      </div>
      <div className="dataTables_paginate paging_simple_numbers">
        <Pagination className="pagination-primary pagination-circle" size="lg">
          <li
            className="page-item page-indicator "
            onClick={() => {
              if (index > 0) {
                setIndex(index - 1);
              }
            }}
          >
            <Link className="page-link" to="#">
              <i className="la la-angle-left" />
            </Link>
          </li>
          {<Pagination.Item className={"active"}>{index + 1}</Pagination.Item>}
          <li
            className="page-item page-indicator"
            onClick={() => {
              if (data.length === perPage) {
                setIndex(index + 1);
              }
            }}
          >
            <Link className="page-link" to="#">
              <i className="la la-angle-right" />
            </Link>
          </li>
        </Pagination>
      </div>
    </div>
  );
}

export default UsePagination;