import React, { useState } from 'react'
import { Button, Field, Persona, Dropdown, Option, Tooltip, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTrigger, DialogTitle, TagGroup, InteractionTag, InteractionTagPrimary, InteractionTagSecondary, Spinner } from '@fluentui/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { GetAppList, GetAppVersions } from '../../features/applicationSlice';
import { toast } from 'react-toastify';
import { AssignApp } from '../../features/configurationSlice';
import { APK_ACTION } from '../constants/constants';
import { Col } from 'react-bootstrap';

const AssignAppModal = ({configuration_id}) => {
    const dispatch = useDispatch();
    const { apps, versions, isLoading } = useSelector((state) => state.app);
    const [isAppSelected, setAppSelected] = useState(false);
    const [appVersion, setAppVersion] = useState("1");
    const actions = [
      { label: "Install", value: 1 },
      { label: "Uninstall", value: 2 },
      { label: "Hide", value: 3 },
    ];

    const [data, setData] = useState({
        "config_id": configuration_id,
        "app_id": null,
        "apk_action": null
    });

    const onSubmit = (e) =>{
        e.preventDefault();

        if (!data.app_id || !data.apk_action) {
          toast.warning("All fields are required!");
          return;
        }
        const tst = toast.loading("Assigning App...");
        dispatch(AssignApp(data)).then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            toast.update(tst, {
              render: "Application assigned successfully!",
              type: "success",
              isLoading: false,
              autoClose: 3000,
              closeButton: true,
            });
          } else {
            toast.dismiss(tst);
          }
        });
    }

    const onShow = () => {
      setData({
        config_id: configuration_id,
        app_id: null,
        apk_action: null,
      });
      setAppSelected(false);
        if(apps.length === 0){
            dispatch(GetAppList({
                "limit": 150,
                "offset": 0
            }));
        }
    }


  return (
    <Dialog modalType="non-modal">
      <DialogTrigger disableButtonEnhancement>
        <Tooltip withArrow content={"Assign Application"}>
          <Button
            onClick={onShow}
            icon={<i className="la la-android"></i>}
          ></Button>
        </Tooltip>
      </DialogTrigger>
      <DialogSurface aria-describedby={undefined}>
        <form onSubmit={(e) => onSubmit(e)}>
          <DialogBody>
            <DialogTitle>Assign Application</DialogTitle>
            <DialogContent>
              <Field
                required
                label={"Select Application"}
                className="form-group"
                size="large"
              >
                <Dropdown
                  onOptionSelect={(e, d) => {
                    setAppSelected(true);
                    setData({ ...data, app_id: null });
                    dispatch(GetAppVersions({ id: d.optionValue }));
                  }}
                >
                  {apps.map((app) => (
                    <Option key={app.id} text={app.name} value={app.id}>
                      <Persona
                        avatar={{ color: "colorful" }}
                        name={app.name}
                        secondaryText={app.package_name}
                        tertiaryText={app.version}
                      />
                    </Option>
                  ))}
                </Dropdown>
              </Field>
              {isAppSelected &&
                (isLoading ? (
                  <Spinner
                    appearance="primary"
                    labelPosition="after"
                    label={"Loading app versions ..."}
                  />
                ) : (
                  <TagGroup
                    className="form-group d-flex"
                    style={{overflowX: "auto"}}
                  >
                    {versions.map((ver) => (
                        <InteractionTag
                          appearance={
                            data.app_id === ver.id ? "brand" : "outline"
                          }
                          key={ver.id}
                          value={ver.id}
                          onClick={(e) => {
                            e.preventDefault();
                            setData({ ...data, app_id: ver.id });
                            setAppVersion(ver.version_name);
                          }}
                        >
                          <InteractionTagPrimary hasSecondaryAction>
                            {ver.version_name}
                          </InteractionTagPrimary>
                          <InteractionTagSecondary>
                            {data.app_id === ver.id ? (
                              <i className="la la-check"></i>
                            ) : (
                              <i className="la la-times"></i>
                            )}
                          </InteractionTagSecondary>
                        </InteractionTag>
                      
                    ))}
                  </TagGroup>
                ))}
              <Field
                required
                label={"APK Action"}
                className="form-group"
                size="large"
              >
                <Dropdown
                  onOptionSelect={(e, d) =>
                    setData({ ...data, apk_action: parseInt(d.optionValue) })
                  }
                >
                  {Object.keys(APK_ACTION).filter(item => {
                    return appVersion === "0" ? !["1", "2"].includes(item) : item
                  }).map((ac) => (
                    <Option key={ac} text={APK_ACTION[ac]} value={ac}>
                      {APK_ACTION[ac]}
                    </Option>
                  ))}
                </Dropdown>
              </Field>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Close</Button>
              </DialogTrigger>
              <Button type="submit" appearance="primary">
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
}

export default AssignAppModal;