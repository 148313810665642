import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  Button,
 Card,
 CardHeader,
  Spinner,
  Tooltip,
  Switch,
} from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AssignPolicy, GetConfigOrDevicePolicy, GetPolicyList } from "../../features/policySlice";

function AssignPolicyModal({ id }) {
  const dispatch = useDispatch();
  const {policies, isLoading, dPolicy} = useSelector(state => state.policy);
  const [data, setData] = useState({
    "config_or_dev_id": id,
    "policy_list": []
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const tst = toast.loading("Assigning Policies...");
    
      dispatch(AssignPolicy(data)).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          toast.update(tst, {
            render: "Policies assigned successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
          });
        } else {
          toast.dismiss(tst);
        }
      });
    
  };

  const onShow = () => {

    setData({
      config_or_dev_id: id,
      policy_list: [],
    });

    if(policies.length === 0){
        dispatch(GetPolicyList({
                "limit": 150,
                "offset": 0
            }));
    }

    dispatch(GetConfigOrDevicePolicy({id}));
  }

  const onChange = (e, d) => {
   
    if (e.currentTarget.checked) {
      setData({ ...data, policy_list: [...data.policy_list, d] });
    } else {
      const updatedData = [...data.policy_list];
      //updatedData.splice(updatedData.indexOf(d), 1);
      const index = updatedData.findIndex((obj) => obj.id === d.id);
      if (index !== -1) {
        updatedData.splice(index, 1);
      }
      setData((prevData) => ({
        ...prevData,
        policy_list: updatedData,
      }));
    }
  }

  useEffect(() => {
    const updatedData = dPolicy.policy_list ? [...dPolicy.policy_list] : [];
    setData((prevData) => ({
      ...prevData,
      policy_list: updatedData,
    }));
  }, [dPolicy]);

  

  return (
    <Dialog modalType="non-modal">
      <DialogTrigger disableButtonEnhancement>
        <Tooltip withArrow content={"Assign Policy"}>
          <Button
            onClick={onShow}
            icon={<i className="las la-file-alt"></i>}
          ></Button>
        </Tooltip>
      </DialogTrigger>
      <DialogSurface aria-describedby={undefined}>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <DialogTitle>{"Assign Policy"}</DialogTitle>
            <DialogContent className="d-flex flex-column">
              {isLoading ? (
                <Spinner
                  appearance="primary"
                  label={"Getting Policies List..."}
                  labelPosition="after"
                />
              ) : (
                policies.map((policy) => (
                  <Card className="mb-3" key={policy.id} size="small" role="listitem">
                    <CardHeader
                      header={
                        <Switch
                          key={policy.id}
                          onChange={(e) => onChange(e, {"id": policy.id, "name": policy.name})}
                          value={policy.id}
                          label={policy.name}
                          checked={data.policy_list.find(p => p.id === policy.id)}
                          shape="circular"
                        />
                      }
                    />
                  </Card>
                ))
              )}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Close</Button>
              </DialogTrigger>
              <Button type="submit" appearance="primary">
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
}

export default AssignPolicyModal;
