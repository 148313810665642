import AddOrUpdateGroup from "./modal";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "@fluentui/react-components";
import Moment from "react-moment";


function GroupTable({ data }) {

  return (

      <div className="table-responsive">
        <Table sortable className="table table-striped">
          <TableHeader>
            <TableRow>
              <TableHeaderCell scope="col">Name</TableHeaderCell>
              <TableHeaderCell scope="col">Created At</TableHeaderCell>
              <TableHeaderCell scope="col">Last Update</TableHeaderCell>
              <TableHeaderCell scope="col">Action</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <TableCellLayout media={<Avatar name={item.name} />}>
                    {item.name}
                  </TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellLayout
                    media={<i className="las la-1x la-plus-square"></i>}
                  >
                    <Moment format="LLL">{item.created_at}</Moment>
                  </TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellLayout
                    media={<i className="las la-1x la-edit"></i>}
                  >
                    <Moment format="LLL">{item.modified_at}</Moment>
                  </TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellLayout>
                    <AddOrUpdateGroup data={item} />
                  </TableCellLayout>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
  );
}

export default GroupTable;
