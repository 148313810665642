import { useEffect, React, useState } from "react";
import GroupTable from "./table";
import { useDispatch, useSelector } from "react-redux";
import AddOrUpdateUser from "./modal";
import { Card} from "react-bootstrap";
import { Spinner} from "@fluentui/react-components";
import CustomMessageBar from "../CustomMessageBar";
import { GetGroupList, reset } from "../../features/groupSlice";
import UsePagination from "../../hooks/pagination";
import UseSearch from "../../hooks/search";

function GroupManagement() {
  const [searchField, setSearchField] = useState("name");
  const [search, setSearch] = useState("");
  const options = [{ label: "Name", value: "name" }];
  const { groups, isLoading, isError, message } = useSelector(
    (state) => state.group
  );
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const [perPage, setPerPage] = useState(150);
  const pagination = UsePagination(groups, index, perPage, setPerPage, setIndex);
  const SearchForm = UseSearch(options, setSearchField, setSearch);

  

  useEffect(() => {
    dispatch(
      GetGroupList({
        limit: perPage,
        offset: index * perPage,
      })
    );

    return () => {
      dispatch(reset());
    };
  }, [index, perPage, dispatch]);

  return (
    <Card>
      <Card.Header>
        <Card.Title className="d-flex align-items-center">
          {isLoading && (
            <>
              <Spinner appearance="primary" /> &nbsp;
            </>
          )}{" "}
          Group List
        </Card.Title>
        <AddOrUpdateUser data={null} />
      </Card.Header>
      <Card.Body>
        {SearchForm}
        {isError && groups.length === 0 ? (
          !isLoading && (
            <CustomMessageBar
              intent={"error"}
              title={"Error"}
              message={message}
              action={() =>
                dispatch(
                  GetGroupList({
                    limit: perPage,
                    offset: index * perPage,
                  })
                )
              }
            />
          )
        ) : (
          <GroupTable
            data={groups.filter((u) => {
              return u[searchField]
                .toString()
                .toLowerCase()
                .includes(search.toLowerCase());
            })}
          />
        )}
      </Card.Body>
      <Card.Footer>{pagination}</Card.Footer>
    </Card>
  );
}

export default GroupManagement;
