import React, { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  Input,
  Button,
  Field,
} from "@fluentui/react-components";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { CreatePolicy, UpdatePolicy } from "../../features/policySlice";

function AddOrUpdatePolicy({ data }) {
  const dispatch = useDispatch();

  const [policy, setPolicy] = useState({
    id: data?.id ?? "",
    name: data?.name ?? "",
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    setPolicy({ ...policy, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const tst = toast.loading("Loading...");

    if (data) {
      dispatch(UpdatePolicy(policy)).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          toast.update(tst, {
            render: "Policy updated successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
          });
        } else {
          toast.dismiss(tst);
        }
      });
    } else {
      dispatch(CreatePolicy({ name: policy.name })).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          toast.update(tst, {
            render: "Policy created successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
          });
        } else {
          toast.dismiss(tst);
        }
      });
    }
  };

  return (
    <Dialog modalType="non-modal">
      <DialogTrigger disableButtonEnhancement>
        {data ? (
          <Button icon={<i className="las la-pen"></i>}>Edit</Button>
        ) : (
          <Button appearance="primary" icon={<i className="las la-file-alt"></i>}>
            Add
          </Button>
        )}
      </DialogTrigger>
      <DialogSurface aria-describedby={undefined}>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <DialogTitle>{data ? "Update Policy" : "Add Policy"}</DialogTitle>
            <DialogContent>
              <Field label="Name" required size="large" className="form-group">
                <Input
                  value={policy.name}
                  name="name"
                  onChange={onChangeInput}
                />
              </Field>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Close</Button>
              </DialogTrigger>
              <Button type="submit" appearance="primary">
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
}

export default AddOrUpdatePolicy;
