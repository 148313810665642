import { useEffect, React, useState } from "react";
import ConfigurationTable from "./table";
import { useDispatch, useSelector } from "react-redux";
import AddOrUpdateConfiguration from "./modal";
import { Card } from "react-bootstrap";
import { Spinner } from "@fluentui/react-components";
import CustomMessageBar from "../CustomMessageBar";
import { GetConfigurationList, reset } from "../../features/configurationSlice";
import UsePagination from "../../hooks/pagination";
import UseSearch from "../../hooks/search";
import { reset as AppReset } from "../../features/applicationSlice";

function ConfigurationManagement() {
  const [searchField, setSearchField] = useState("name");
  const [search, setSearch] = useState("");
  const options = [{ label: "Name", value: "name" }];
  const { configurations, isLoading, isError, message } = useSelector(
    (state) => state.configuration
  );
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const [perPage, setPerPage] = useState(150);
  const pagination = UsePagination(
    configurations,
    index,
    perPage,
    setPerPage,
    setIndex
  );
  const SearchForm = UseSearch(options, setSearchField, setSearch);

  useEffect(() => {
    dispatch(
      GetConfigurationList({
        limit: perPage,
        offset: index * perPage,
      })
    );

    return () => {
      dispatch(reset());
      dispatch(AppReset());
    };
  }, [index, perPage, dispatch]);

  return (
    <Card>
      <Card.Header>
        <Card.Title className="d-flex align-items-center">
          {isLoading && (
            <>
              <Spinner appearance="primary" /> &nbsp;
            </>
          )}{" "}
          Configuration List
        </Card.Title>
        <AddOrUpdateConfiguration data={null} />
      </Card.Header>
      <Card.Body>
        {SearchForm}
        {isError && configurations.length === 0 ? (
          !isLoading && (
            <CustomMessageBar
              intent={"error"}
              title={"Error"}
              message={message}
              action={() =>
                dispatch(
                  GetConfigurationList({
                    limit: perPage,
                    offset: index * perPage,
                  })
                )
              }
            />
          )
        ) : (
          <ConfigurationTable
            data={configurations.filter((u) => {
              return u[searchField]
                .toString()
                .toLowerCase()
                .includes(search.toLowerCase());
            })}
          />
        )}
      </Card.Body>
      <Card.Footer>{pagination}</Card.Footer>
    </Card>
  );
}

export default ConfigurationManagement;
