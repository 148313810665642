import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Input, Select } from '@fluentui/react-components';

const UseSearch = (options, setSearchField, setSearch) => {
  return (
    <Form className="py-3">
      <Row>
        <Col lg={3}>
          <div className="form-group">
            <Select
              className="w-100"
              onChange={(e) => setSearchField(e.target.value)}
            >
              {options.map((opt) => (
                <option value={opt.value}>{opt.label}</option>
              ))}
            </Select>
          </div>
        </Col>
        <Col lg={9}>
          <div className="form-group w-100">
            <Input
              className="w-100"
              contentBefore={<i className="las la-1x la-search"></i>}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default UseSearch;