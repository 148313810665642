import React, { useState } from "react";
import {
  Button,
  Tooltip,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTrigger,
  DialogTitle,
  Card,
  CardHeader,
  Caption1,
  Text,
  Spinner,
  Badge,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  TagGroup,
  InteractionTag,
  InteractionTagPrimary,
  InteractionTagSecondary,
  makeStyles,
  Title3,
  Body2,
  Select,
  useOverflowMenu,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuItem,
  Overflow,
  OverflowItem
} from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { ConfigUpdateApp, GetConfigApps } from "../../../features/configurationSlice";
import { toast } from "react-toastify";
import { Col } from "react-bootstrap";
import { ACTION_COLOR, APK_ACTION } from "../../constants/constants";
import { GetAppVersions } from "../../../features/applicationSlice";
import { TeachingPopover, TeachingPopoverBody, TeachingPopoverHeader, TeachingPopoverSurface, TeachingPopoverTrigger } from "@fluentui/react-teaching-popover-preview";
import UseSearch from "../../../hooks/search";

const useStyles = makeStyles({
  width: { width: "70% !important" },
});

const ConfigurationApps = ({ configuration_id }) => {
  const dispatch = useDispatch();
  const { configApps, isLoading } = useSelector((state) => state.configuration);
  const appState = useSelector(state => state.app);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [searchField, setSearchField] = useState("name");
  const [search, setSearch] = useState("");
  const options = [
    { label: "Name", value: "name" },
    { label: "Package Name", value: "package_name" },
    { label: "Version", value: "version_name" },
  ];

  const SearchForm = UseSearch(options, setSearchField, setSearch);
  

  const [data, setData] = useState({
    config_id: configuration_id,
    old_app_id: null,
    new_app_id: null,
    apk_action: null
  });

  const { ref, isOverflowing, overflowCount } = useOverflowMenu();

  const onSubmit = (e) => {
    e.preventDefault();

    if (!data.old_app_id || !data.new_app_id) {
      toast.warning("All fields are required!");
      return;
    }

    const tst = toast.loading("Updating App...");
    dispatch(ConfigUpdateApp(data)).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.update(tst, {
          render: "Application updated successfully!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeButton: true,
        });

        dispatch(
          GetConfigApps({
            config_id: configuration_id,
            limit: 150,
            offset: 0,
          })
        );
      } else {
        toast.dismiss(tst);
      }
    });
  };

  const onShow = () => {
      dispatch(
        GetConfigApps({
          config_id: configuration_id,
          limit: 150,
          offset: 0,
        })
      );
  };

  

  const getAppVerions = (id) => {
    dispatch(GetAppVersions({ id}));
  }

  const classes = useStyles();

  const OverflowMenu = () => {

    if(appState.versions.length < 3){
        return null;
    }

    return (
      <InteractionTag>
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <InteractionTagPrimary
              ref={ref}
              aria-label={`${overflowCount} more tags`}
            >
              {`+${appState.versions.slice(3).length}`}
            </InteractionTagPrimary>
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              {appState.versions.slice(3).map((ver) => (
                <MenuItem key={ver.id}>
                  <InteractionTag
                    appearance={
                      selectedVersion === ver.id ? "brand" : "outline"
                    }
                    value={ver.id}
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedVersion(ver.id);
                      setData({
                        ...data,
                        new_app_id: ver.id,
                      });
                    }}
                  >
                    <InteractionTagPrimary hasSecondaryAction>
                      {ver.version_name}
                    </InteractionTagPrimary>
                    <InteractionTagSecondary>
                      {selectedVersion === ver.id ? (
                        <i className="la la-check"></i>
                      ) : (
                        <i className="la la-times"></i>
                      )}
                    </InteractionTagSecondary>
                  </InteractionTag>
                </MenuItem>
              ))}
            </MenuList>
          </MenuPopover>
        </Menu>
      </InteractionTag>
    );
  };


  return (
    <Dialog className={classes.width} modalType="non-modal">
      <DialogTrigger disableButtonEnhancement>
        <Tooltip withArrow content={"Assigned Applications List"}>
          <Button
            onClick={onShow}
            icon={<i className="la la-list"></i>}
          ></Button>
        </Tooltip>
      </DialogTrigger>
      <DialogSurface className={classes.width} aria-describedby={undefined}>
        <DialogBody>
          <DialogTitle>Assigned Applications List</DialogTitle>
          <DialogContent style={{ overflowX: "hidden" }}>
            {SearchForm}
            {isLoading ? (
              <Spinner
                appearance="primary"
                labelPosition="after"
                label="Getting app list..."
              />
            ) : (
              configApps
                .filter((app) => {
                  return app[searchField]
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase());
                })
                .map((app, i) => (
                  <Card key={i} className="mb-3" size="large" role="listitem">
                    <CardHeader
                      image={<i className="la la-3x la-android"></i>}
                      header={<Text weight="semibold">{app.name}</Text>}
                      description={
                        <>
                          <div className="d-flex flex-column">
                            <div>
                              <Caption1>{app.package_name}</Caption1>{" "}
                              <Badge
                                className="ml-2"
                                color={ACTION_COLOR[app.apk_action]}
                                shape="rounded"
                                size="medium"
                              >
                                {APK_ACTION[app.apk_action]}
                              </Badge>
                            </div>
                            <Caption1>{app.version_name}</Caption1>
                          </div>
                        </>
                      }
                      action={
                        <div className="d-flex flex-row">
                          <TeachingPopover>
                            <TeachingPopoverTrigger disableButtonEnhancement>
                              <Tooltip withArrow content={"Update Version"}>
                                <Button
                                  icon={
                                    <i className="las la-1x la-sitemap"></i>
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedVersion(app.app_id);
                                    setData({
                                      ...data,
                                      old_app_id: app.app_id,
                                      new_app_id: null,
                                      apk_action: app.apk_action,
                                    });
                                    getAppVerions(app.id);
                                  }}
                                ></Button>
                              </Tooltip>
                            </TeachingPopoverTrigger>

                            <TeachingPopoverSurface
                              tabIndex={-1}
                              className="d-flex flex-column"
                            >
                              <TeachingPopoverHeader className="pb-3">
                                Update Version
                              </TeachingPopoverHeader>
                              <TeachingPopoverBody>
                                {appState.isLoading ? (
                                  <Spinner
                                    appearance="primary"
                                    labelPosition="after"
                                    label="Getting app versions..."
                                  />
                                ) : (
                                  <Overflow minimumVisible={3} padding={60}>
                                    <TagGroup>
                                      {appState.versions.map((ver) => (
                                        <OverflowItem key={ver.id} id={ver.id}>
                                          <InteractionTag
                                            appearance={
                                              selectedVersion === ver.id
                                                ? "brand"
                                                : "outline"
                                            }
                                            value={ver.id}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setSelectedVersion(ver.id);
                                              setData({
                                                ...data,
                                                new_app_id: ver.id,
                                              });
                                            }}
                                          >
                                            <InteractionTagPrimary
                                              hasSecondaryAction
                                            >
                                              {ver.version_name}
                                            </InteractionTagPrimary>
                                            <InteractionTagSecondary>
                                              {selectedVersion === ver.id ? (
                                                <i className="la la-check"></i>
                                              ) : (
                                                <i className="la la-times"></i>
                                              )}
                                            </InteractionTagSecondary>
                                          </InteractionTag>
                                        </OverflowItem>
                                      ))}

                                      <OverflowMenu />
                                    </TagGroup>
                                  </Overflow>
                                )}
                                {data.new_app_id &&
                                  data.new_app_id !== data.old_app_id && (
                                    <>
                                      <div className="d-flex justify-content-center py-3">
                                        {appState.versions
                                          .filter(
                                            (v) => v.id === data.old_app_id
                                          )
                                          .map((v) => (
                                            <Body2>{v.version_name}</Body2>
                                          ))}
                                        <i class="las la-2x la-exchange-alt px-3"></i>
                                        {appState.versions
                                          .filter(
                                            (v) => v.id === data.new_app_id
                                          )
                                          .map((v) => (
                                            <Body2>{v.version_name}</Body2>
                                          ))}
                                      </div>
                                      <Button
                                        appearance="primary"
                                        onClick={onSubmit}
                                      >
                                        Update
                                      </Button>
                                    </>
                                  )}
                              </TeachingPopoverBody>
                            </TeachingPopoverSurface>
                          </TeachingPopover>

                          <TeachingPopover>
                            <TeachingPopoverTrigger disableButtonEnhancement>
                              <Tooltip withArrow content={"Update APK Action"}>
                                <Button
                                  icon={<i className="las la-1x la-cog"></i>}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedVersion(app.app_id);
                                    setData({
                                      ...data,
                                      old_app_id: app.app_id,
                                      new_app_id: app.app_id,
                                      apk_action: app.apk_action,
                                    });
                                    getAppVerions(app.id);
                                  }}
                                ></Button>
                              </Tooltip>
                            </TeachingPopoverTrigger>

                            <TeachingPopoverSurface
                              tabIndex={-1}
                              className="d-flex flex-column"
                            >
                              <TeachingPopoverHeader className="pb-3">
                                Update APK Action
                              </TeachingPopoverHeader>
                              <TeachingPopoverBody>
                                <Select
                                  className="form-group"
                                  onChange={(e) =>
                                    setData({
                                      ...data,
                                      apk_action: parseInt(e.target.value),
                                    })
                                  }
                                  value={data.apk_action}
                                >
                                  {Object.keys(APK_ACTION)
                                    .filter((item) => {
                                      return app.version_name === "0"
                                        ? !["1", "2"].includes(item)
                                        : item;
                                    })
                                    .map((action) => (
                                      <option value={parseInt(action)}>
                                        {APK_ACTION[action]}
                                      </option>
                                    ))}
                                </Select>
                                <Button appearance="primary" onClick={onSubmit}>
                                  Update
                                </Button>
                              </TeachingPopoverBody>
                            </TeachingPopoverSurface>
                          </TeachingPopover>
                        </div>
                      }
                    />
                  </Card>
                ))
            )}
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Close</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default ConfigurationApps;


