import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createDevice, getDeviceList, lockUnlockDevice, resetDevice, updateDevice } from "../api";
import { toast } from "react-toastify";

const initialState = {
  devices: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const GetDeviceList = createAsyncThunk(
  "device/getList",
  async (pagination, thunkAPI) => {
    try {
      return await getDeviceList(pagination);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const CreateDevice = createAsyncThunk(
  "device/create",
  async (data, thunkAPI) => {
    try {
      return await createDevice(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const UpdateDevice = createAsyncThunk(
  "device/update",
  async (data, thunkAPI) => {
    try {
      return await updateDevice(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const LockDevice = createAsyncThunk(
  "device/lock",
  async (data, thunkAPI) => {
    try {
      return await lockUnlockDevice(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const FactoryResetDevice = createAsyncThunk(
  "device/reset",
  async (data, thunkAPI) => {
    try {
      return await resetDevice(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const DeviceSlice = createSlice({
  name: "DeviceSlice",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetDeviceList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetDeviceList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.devices = action.payload;
      })
      .addCase(GetDeviceList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(CreateDevice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateDevice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.devices.push(action.payload);
      })
      .addCase(CreateDevice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(UpdateDevice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpdateDevice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.devices = state.devices.map((d) =>
          d.id === action.payload.id
            ? {
                ...d,
                description: action.payload.description,
                imei: action.payload.imei,
                configuration_id: action.payload.configuration_id,
              }
            : d
        );
      })
      .addCase(UpdateDevice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(LockDevice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(LockDevice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.devices = state.devices.map((d) =>
          d.id === action.payload.id
            ? {
                ...d,
                device_lock: action.payload.status
              }
            : d
        );
      })
      .addCase(LockDevice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(FactoryResetDevice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(FactoryResetDevice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.devices = state.devices.map((d) =>
          d.id === action.payload.id
            ? {
                ...d,
                device_reset: action.payload.status,
              }
            : d
        );
      })
      .addCase(FactoryResetDevice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const { reset } = DeviceSlice.actions;
export default DeviceSlice.reducer;
