import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { UploadAPK } from "../../features/applicationSlice";

function UploadAppAPK() {
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
 

  const onSubmit = (e) => {
    e.preventDefault();

    if(!file){
        toast.warning("Please select apk file!");
        return;
    }

    const tst = toast.loading("Uploading Apk...");
    const data = new FormData();
    data.append(file.name.replace(".apk", ""), file);

    dispatch(UploadAPK(data)).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.update(tst, {
          render: "APK uploaded successfully!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeButton: true,
        });
      } else {
        toast.dismiss(tst);
      }
    });
    
  };

  return (
    <Dialog modalType="non-modal">
      <DialogTrigger disableButtonEnhancement>
        <Button appearance="primary" icon={<i className="la la-android"></i>}>Upload APK</Button>
      </DialogTrigger>
      <DialogSurface aria-describedby={undefined}>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <DialogTitle>Upload APK</DialogTitle>
            <DialogContent>
              <div className="shadow p-3 my-3">
                <input
                  type="file"
                  required
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Close</Button>
              </DialogTrigger>
              <Button type="submit" appearance="primary">
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
}

export default UploadAppAPK;
