import { useEffect, React, useState } from "react";

import UserTable from "./table";
import { useDispatch, useSelector } from "react-redux";
import AddOrUpdateUser from "./modal";
import { Card, Col, Form, Row } from "react-bootstrap";
import UserCounts from "./counts";
import { Select, Input, Spinner } from "@fluentui/react-components";
import { GetUserList, reset } from "../../../features/userSlice";
import CustomMessageBar from "../../CustomMessageBar";
import { GetGroupList, reset as GReset } from "../../../features/groupSlice";
import UsePagination from "../../../hooks/pagination";
import UseSearch from "../../../hooks/search";





function User() {
   const [searchField, setSearchField] = useState('full_name');
   const options = [
     { label: "Name", value: "full_name" },
     { label: "Username", value: "username" },
     { label: "Phone", value: "phone" },
     { label: "Email", value: "email" },
   ];
   const [search, setSearch] = useState('');
   const {users, isLoading, isError, message} = useSelector(state => state.user);
   const dispatch = useDispatch();
   const [index, setIndex] = useState(0);
   const [perPage, setPerPage] = useState(150);
   const pagination = UsePagination(
     users,
     index,
     perPage,
     setPerPage,
     setIndex
   );

   const SearchForm = UseSearch(options, setSearchField, setSearch);

   useEffect(() => {
    dispatch(
      GetUserList({
        limit: perPage,
        offset: index * perPage,
      })
    );

    dispatch(
      GetGroupList({
        limit: 150,
        offset: 0,
      })
    );

    return () => {
      dispatch(reset());
      dispatch(GReset());
    }
   },[dispatch]);


  return (
    <Card>
      <Card.Header>
        <Card.Title className="d-flex align-items-center">
          {isLoading && (
            <>
              <Spinner appearance="primary" /> &nbsp;
            </>
          )}{" "}
          User List
        </Card.Title>
        <AddOrUpdateUser data={null} />
      </Card.Header>
      <Card.Body>
        <UserCounts data={users} />
        {SearchForm}
        {isError && users.length === 0 ? (
          !isLoading && (
            <CustomMessageBar
              intent={"error"}
              title={"Error"}
              message={message}
              action={() =>
                dispatch(
                  GetUserList({
                    limit: perPage,
                    offset: index * perPage,
                  })
                )
              }
            />
          )
        ) : (
          <UserTable
            data={users.filter((u) => {
              return u[searchField]
                .toString()
                .toLowerCase()
                .includes(search.toLowerCase());
            })}
          />
        )}
      </Card.Body>
      <Card.Footer>{pagination}</Card.Footer>
    </Card>
  );
}

export default User;
